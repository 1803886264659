import React, {Component} from 'react';
import {appendScript} from "./components/utils/Utils";
import {Heading1} from "./components/Heading1";
import axios from "axios";
import App from "../../App";
import {getToken, getUser, isAnnonym} from "./User";
import {Cart} from "./Cart";
import $ from "jquery";
import {Redirect} from "react-router-dom";
import MetaTags from "react-meta-tags";
import {Home} from "./Home";

export class Order extends Component {
    static displayName = Order.name;
    static title = "Doprava a platba";
    static url = "/doprava-a-platba";

    constructor(props) {
        super(props);

        this.onValueChangePayment = this.onValueChangePayment.bind(this);
        this.onValueChangeShipping = this.onValueChangeShipping.bind(this);

        this.state = {
            loading: true,
            paymentMethods: null,
            shippingMethods: null,
            selectedPaymentOption: null,
            selectedShippingOption: null,
            dteReady: false,
        }
    }

    componentDidMount() {
        //console.log(this.props.dteReady);

        if (getUser() !== null && !isAnnonym()) {
            const element = document.createElement("TDEshopCheckout");
            element.async = true;
            //element.async = false;
            let container = $('.order__container');
            container.append(element);
            
        }
        
        if (!this.props.dteReady) {
            //this.props.handleUpdateDteReady(true);

            let apiUrl = App.apiUrl;
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = false;
            let token = getToken().toString();

            let code =
                'var token = \'' + token + '\';' +
                '    function UpdateShoppingCartInfo() {\n' +
                '        // this function need to be defined\n' +
                '        // update shopping cart info on your page here\n' +
                '    }\n' +
                '\n' +
                '    function getTDEshopWebApiUrl() {\n' +
                '        return \'' + apiUrl + '\';\n' +
                '    }\n' +
                '\n' +
                '    function getTDEshopWebApiToken() {\n' +
                '        if (token == null) {\n' +
                '            token = window.prompt("Enter token");\n' +
                '        }\n' +
                '\n' +
                '        return this.token;\n' +
                '    }';
            try {
                s.appendChild(document.createTextNode(code));
                document.body.appendChild(s);
            } catch (e) {
                s.text = code;
                document.body.appendChild(s);
            }


            if (getUser() !== null && !isAnnonym()) {
                appendScript((App.apiUrl + '/scripts/checkout/1_inline.3ef71e5a94220867801d.bundle.js'), false);
                appendScript((App.apiUrl + '/scripts/checkout/2_polyfills.d56b6c8d0354ab6a8f39.bundle.js'), false);
                appendScript((App.apiUrl + '/scripts/checkout/3_scripts.e2cc764d74d6cb8d1c42.bundle.js'), false);
                appendScript((App.apiUrl + '/scripts/checkout/4_main.27705fd38c2da170f046.bundle.js'), false);
            }
        }
        
    }

    onValueChangePayment(event) {
        this.setState({
            selectedPaymentOption: Number.parseInt(event.target.value, 10)
        });
    }

    onValueChangeShipping(event) {
        this.setState({
            selectedShippingOption: Number.parseInt(event.target.value, 10)
        });
    }

    async getDelivery() {
        return  axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get('/api/checkout/delivery');
    }

    getContainer() {

        return (
            <>
                <form>

                    {this.state.paymentMethods.map(item => {
                        return (
                            <>
                                <label for={item.Id}>
                                    <input name={item.Id}
                                           type="radio"
                                           value={item.Id}
                                           checked={this.state.selectedPaymentOption === item.Id}
                                           onChange={this.onValueChangePayment}
                                    />
                                    <span>{item.Name}</span>
                                </label>
                            </>
                        )
                    })}
                </form>

                <form>
                    {this.state.shippingMethods.map(item => {
                        return (
                            <>
                                <label for={item.Id}>
                                    <input name={item.Id}
                                           type="radio"
                                           value={item.Id}
                                           checked={this.state.selectedShippingOption === item.Id}
                                           onChange={this.onValueChangeShipping}
                                    />
                                    <span>{item.Name}</span>
                                </label>
                            </>
                        )
                    })}
                </form>
            </>
        )

    }

    render() {

        if (getUser() === null || isAnnonym()) {
            return (
                <Redirect to={Home.url} />    
                )
        }

        return (
            <>
                <MetaTags>
                    <title>{Order.title}</title>
                    <meta name="description" content="" />
                    <meta name="robots" content="nofollow, noindex" />
                    <meta property="og:title" content={Order.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={Order.title} links={[Cart]}/>

                <section className="section-padding-0-100">
                    <div className="container order__container">
                        
                    </div>
                </section>
            </>
        );
    }
}
