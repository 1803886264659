import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import MetaTags from "react-meta-tags";

export class Postage extends Component {
    static displayName = this.name;
    static title = "Doprava a poštovné"
    static url = "/doprava-a-postovne";

    constructor(props) {
        super(props);
        this.state = {}
    }
    


    render() {

        return (
            <>
                <MetaTags>
                    <title>{Postage.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={Postage.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={Postage.title}/>

                <section className="section-padding-0-100">
                    <div className="container">
                        <h2>Doprava a poštovné</h2>
                        
                        <div className="heading">Vámi objednané zboží Ceylon Way Vám můžeme doručit těmito způsoby:</div>

                        <ul>
                            <li>•	Zásilkovna Česká republika | 79 CZK</li>
                            <li>•	Zásilkovna Slovensko | 99 CZK</li>
                            <li>•	Nejvýhodnější doručení na adresu Česká republika | 88 CZK</li>
                            <li>•	Osobní odběr sklad Prostějov | Zdarma</li>
                            <li>•	Osobní odběr sklad Průhonice | Zdarma</li>
                            <li>•	Dobírka Česká republika | 20 CZK</li>
                            <li>•	Dobírka Slovensko | 30 CZK</li>
                        </ul>
                        
                    </div>
                </section>
            </>
        );
    }
}
