import React, {Component} from 'react';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
    getAboutUsFirstP,
    getIcons,
    getProductImage,
    owlCarousel,
} from "./components/utils/Utils";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import App from "../../App";
import qs from "qs";

export class Home extends Component {
    static displayName = this.name;
    static title = "Domů"
    static url = "/";
    
    constructor(props) {
        super(props);
        this.state = {
            popularProductLoading: true,
            products: null,
        }
    }

    componentDidMount() {
        owlCarousel();
    }


    async confirmRegistration(username, password) {
        return await axios.post(App.apiUrl + '/api/token',
            qs.stringify({
                username: username,
                password: password,
                grant_type: 'password',
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
    }

    getPopularProducts() {
        return (
            this.state.products.map(item => {

                return (
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="single-product-area mb-50 wow fadeInUp" data-wow-delay="300ms">
                            <Link className="product-thumbnail" to={`/produkt/${item.Code}`} data-toggle="tooltip"
                                  data-placement="top" title="Detail">
                                <img src={getProductImage(item.Id)} alt={item.Name}/>

                                <div className="product-meta-data">

                                </div>
                            </Link>

                            <button className="" onClick={() => (this.handleAddToCart(item.Code))} data-toggle="tooltip"
                                    data-placement="top" title="Add To Cart">
                                <FontAwesomeIcon icon={faShoppingCart}/>
                            </button>

                            <div className="product-desc text-center pt-4">
                                <Link to={`/produkt/${item.Code}`} className="product-title">{item.Title}</Link>
                                <h6 className="price">{item.PriceVat} Kč</h6>
                            </div>
                        </div>
                    </div>
                )
            })

        )
    }

    render() {
        
        const req1 = require('../../assets/images/bg/home-1.jpg');
        const style1 = {
            backgroundImage: 'url(' + req1 + ')',
        }

        const req2 = require('../../assets/images/bg/home-2.jpg');
        const style2 = {
            backgroundImage: 'url(' + req2 + ')',
        }

        /*
        let popularProducts = this.state.popularProductLoading
            ? <LoadingItem title="Načítání produktů"/>
            : this.getPopularProducts();
            
         */

        return (
            <div>
                <MetaTags>
                    <title>Domů - Ceylon Way</title>
                    <meta name="description" content="Vítejte na oficiálních stránkách společnosti Ceylon Way a ve světě prémiových ayurvedských produktů přímo ze srdce Srí Lanky." />
                    <meta property="og:title" content="Ceylon Way" />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <div className="hero-area">
                    <div className="welcome-slides owl-carousel">

                        <div className="single-welcome-slides bg bg-overlay jarallax" style={style1}>
                            <div className="container h-100">
                                <div className="row h-100 align-items-center">
                                    <div className="col-12 col-lg-10">
                                        <div className="welcome-content">
                                            <h2 data-animation="fadeInUp" data-delay="200ms">Ajubovan! Live Long.</h2>
                                            <p data-animation="fadeInUp" data-delay="400ms">
                                                Vítejte na oficiálních stránkách společnosti Ceylon Way a ve světě prémiových ayurvedských produktů přímo ze srdce Srí Lanky.
                                            </p>
                                            <Link to="/kontakt" className="btn btn--primary mt-4" data-animation="bounceInUp"
                                               data-delay="600ms">Kontaktujte nás</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="single-welcome-slides bg bg-overlay jarallax" style={style2}>
                            <div className="container h-100">
                                <div className="row h-100 align-items-center">
                                    <div className="col-12 col-lg-10">
                                        <div className="welcome-content">
                                            <h2 data-animation="fadeInDown" data-delay="200ms">Tradiční a tajné ajurvédské receptury.</h2>
                                            <p data-animation="fadeInDown" data-delay="400ms">
                                                Přinášíme Vám produkty pro zdraví a krásu nejvyšší kvality vyráběné dle prastarých a tajných rodinných receptur.</p>
                                            <Link to="/eshop" className="btn btn--primary mt-4" data-animation="bounceInDown"
                                               data-delay="600ms">Naše produkty</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <section className="famie-benefits-area section-padding-100-0 pb-5">
                    <div className="container">

                        <div className="row justify-content-center">
                            {getIcons()}
                        </div>
                    </div>
                </section>

                <section className="about-us-area">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 col-md-8">
                                <div className="about-us-content mb-100">
                                    <div className="section-heading">
                                        <p>O nás</p>
                                        <h2><span>Náš</span> příběh</h2>
                                        <img src={require('../../assets/images/core/decor.png')} alt=""/>
                                    </div>
                                    {getAboutUsFirstP()}
                                    <Link to="/o-nas" className="btn btn--primary mt-30">Číst více</Link>
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="famie-video-play mb-100">
                                    <img src={require('../../assets/images/bg/about-small.jpg')} alt=""/>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="services-area d-flex flex-wrap">
                    <div className="services-thumbnail bg jarallax">
                        <img src={require('../../assets/images/bg/our-products.jpg')} alt="Naše produkty" />
                    </div>

                    <div className="services-content section-padding-100-50 px-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-heading">
                                        <p>Co nabízíme</p>
                                        <h2><span>Naše produkty</span></h2>
                                        <img src={require('../../assets/images/core/decor.png')} alt=""/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mb-50">
                                    <p>Vítejte ve světě prémiových ajurvédských produktů pro zdraví a krásu, které jsou vyráběny dle tajných prastarých ajurvédských receptur. Všechny naše výrobky pocházejí ze srdce Srí Lanky a na jejich výrobu jsou používány výhradně domorodé rostliny s jedinečnými léčivými vlastnostmi, které se datují tisíce let.</p>
                                    <p>Produkty z rodiny Sanjewaaka jsou synonymem pro vysoce účinné, kvalitní a bezpečné přírodní ošetření s prokazatelnými výsledky. Jejich výroba probíhá dle nejvyšších světových standardů kvality.</p>
                                    <p>A my ve společnosti Ceylon Way jsme hrdí na to, že Vám tyto báječné produkty můžeme ve výhradním zastoupení přinášet do České s Slovenské republiky. </p>
                                    {/*
                                    <p>Srí Lanka je domovem řady domorodých rostlin s jedinečnými léčivými vlastnostmi, které se datují tisíce let.
                                        Tyto nadčasové rostliny jsou předávány každé generaci prostřednictvím rodinných praktik. S takovou historií a reputací je rodina Sanjeewaka synonymem pro vytváření kosmetických ošetření, která jsou účinná, bezpečná a odolala zkouškám času. Naše vysoce kvalitní produktová řada s konečnými výsledky je nyní k dispozici po celém světě.</p>
                                        */}
                                    <p><strong>Všechny výrobky jsou testovány a vyhovují požadavkům Nařízení EP a Rady (ES) č. 1223/2009. Výrobky jsou zapsané v Evropském portálu notifikace kosmetických přípravků (CPNP).</strong></p>
                                    <p><strong>Výhradní dovozce: Ceylon Way s.r.o., V Zahradách 925, 252 43 Průhonice, IČ 08536872</strong></p>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="single-service-area mb-50 wow fadeInUp" data-wow-delay="100ms">
                                        <div className="service-title mb-3 d-flex align-items-center">
                                            <img src={require('../../assets/images/icon/face-care.svg')} alt="Péče o obličej"/>
                                            <h5>Péče o obličej</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="single-service-area mb-50 wow fadeInUp" data-wow-delay="300ms">
                                        <div className="service-title mb-3 d-flex align-items-center">
                                            <img src={require('../../assets/images/icon/pain-relieving.svg')} alt="Úleva od bolesti"/>
                                            <h5>Úleva od bolesti</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="single-service-area mb-50 wow fadeInUp" data-wow-delay="500ms">
                                        <div className="service-title mb-3 d-flex align-items-center">
                                            <img src={require('../../assets/images/icon/body-care.svg')} alt="Péče o tělo"/>
                                            <h5>Péče o tělo</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="single-service-area mb-50 wow fadeInUp" data-wow-delay="700ms">
                                        <div className="service-title mb-3 d-flex align-items-center">
                                            <img src={require('../../assets/images/icon/hair-care.svg')} alt="Péče o vlasy"/>
                                            <h5>Péče o vlasy</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section className="news-area bg-gray section-padding-100-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading text-center">
                                    <h2><span>Novinky</span> na Instagramu</h2>
                                    <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mb-80">
                            <div className="elfsight-app-4fcdf018-2a96-4d04-9bd3-7ddaff52eeb5"></div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
