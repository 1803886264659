import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Home} from "../Home";
import {Contact} from "../Contact";
import {Cookie} from "../Cookie";
import {PersonalData} from "../PersonalData";
import {About} from "../About";
import {Product} from "../Product";
import {Team} from "../Team";
import NavLink from "react-router-dom/NavLink";

class NavMenuItems extends Component {
    static displayName = NavMenuItems.name;
    
    
    constructor (props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            active: '/',
            personalItemsP: this.props.personalItemsP ? this.props.personalItemsP : false,
        };
        
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    
    getNavComponents() {
        return [
            {component: Home, object: <Home state={this.state}/>},
            {component: About, object: <About state={this.state}/>},
            {component: Team, object: <Team state={this.state}/>},
            {component: Product, object: <Product state={this.state}/>},
            {component: Contact, object: <Contact state={this.state}/>},
        ];
    }

    getPersonalComponents() {
        return [
            {component: Cookie, object: <Cookie state={this.state}/>},
            {component: PersonalData, object: <PersonalData state={this.state}/>},
        ]
    }
    

    makeLinks(items) {

        return items.map((item, index) => {
            return <li key={index} className='js-nav-item' onClick={() => {
                //showLoader();
                //hideLoader();
                this.props.setTitle(item.component.title);
                //this._handleClick(item.component.url);
            }}>
                <NavLink activeClassName="active" className={item.component.url === '/' ? 'js-nav-link home' : 'js-nav-link'} to={item.component.url}>{item.component.title}</NavLink>
            </li>
        });
    }
    
    render () {
        //const { navItems } = this.props;
        let navItems = '';

        if (typeof navItems === 'undefined') {
            return (
                <strong>Nav items neexistují</strong>
            )
        }
        
        if (!this.state.personalItemsP) {
            return (
                <ul>
                    {this.makeLinks(this.getNavComponents()) }
                </ul>
            );    
        }
        
        return (
            <ul>
                <li><a href={require('../../../assets/document/zasady-zpracovani-cookies.pdf')} target="_blank" rel="noopener noreferrer">Zásady zpracování cookies</a></li>
                <li><a href={require('../../../assets/document/ochrana-osobnich-udaju.pdf')} target="_blank" rel="noopener noreferrer">Ochrana osobních údajů</a></li>
            </ul>
        )
    }
}

export default (NavMenuItems);
