import React, { Component } from 'react'

const TitleContext = React.createContext();

class TitleProvider extends Component {
    // Context state
    state = {
        title: "default title",
    }

    // Method to update state
    setTitle = title => {
        this.setState(prevState => ({ title }))
    }

    render() {
        const { children } = this.props;
        const { title } = this.state;
        const { setTitle } = this;

        return (
            <TitleContext.Provider
                value={{
                    title,
                    setTitle,
                }}
            >
                {children}
            </TitleContext.Provider>
        )
    }
}

export default TitleContext;

export { TitleProvider };