import React, {Component} from 'react';
import {
    faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import qs from "qs";
import {Link, Redirect} from "react-router-dom";
import {LoadingItem} from "./components/loading/LoadingItem";
import {getToken, isAnnonym} from "./User";
import {Cart} from "./Cart";
import App from "../../App";
import {addToCart, getProductImage, hideLoader, showLoader} from "./components/utils/Utils";
import {Heading1} from "./components/Heading1";
import {Login} from "./components/Login";
import swal from "@sweetalert/with-react";
import MetaTags from "react-meta-tags";
import {errorServer} from "./components/error/ServerError";


export class Product extends Component {
    static displayName = Product.name;
    static title = "Naše produkty"
    static url = "/eshop";

    static products = {

        "CW0002": {
            Title: "CHANDANALEPA CINNAMON",
            Tag: "Ayurveda Beauty Soap",
            Description: "Přírodní skořicové bylinné kosmetické mýdlo, připravené dle Ayurvédské receptury, a to mísením přírodní skořicové kůry a skořicového oleje z pravé Ceylonské skořice. Mýdlo odstraňuje odumřelé kožní buňky a udržuje pokožku svěží a pružnou. Eliminuje zčernání slabin, pod pažemi a kolem krku. Při každodenním používání odstraňuje černé skvrny na kůži a hydratuje ji.\n" +
                "Chandanalepa Cinnamon Beauty Soap je ideální pro ranní dávku energie, nebo jako příjemný relax po náročném dni.\n" +
                "Chandanalepa Cinnamon Beauty Soap neobsahuje žádná umělá barviva, parfumace, konzervanty ani živočišný tuk. Je 100% přírodní z certifikovaných RAW surovin.\n" +
                "Pouze pro vnější použití.\n" +
                "Obsahuje: skořicový prášek, skořicový olej, panenský kokosový olej, olivový olej, mýdlovou bázi.",
            Size: "",
        },

        "CW0003": {
            Title: "CHANDANALEPA LEMONGRASS",
            Tag: "Ayurveda Herbal Soap",
            Description: "Ayurvédské ručně vyráběné bylinné mýdlo s extraktem z citrónového oleje, panenského kokosového oleje, olivového ovocného oleje, mandlového oleje, jojobového oleje, hroznového oleje, limetkového ovoce, kurkumy a koření.\n" +
                "Chandanalepa Lemongrass Herbal Soap neobsahuje žádné syntetické přísady ani umělá barviva.\n" +
                "Chandanalepa Lemongrass Herbal Soap Vaši pokožku vyživuje a zanechává ji svěží, vláčnou a mladistvou.\n" +
                "Pouze pro vnější použití.",
            Size: "",
        },

        "CW0005": {
            Title: "CHANDANALEPA GOTUKOLA",
            Tag: "Ayurveda Herbal Soap",
            Description: "Ayurvédské ručně vyráběné bylinné mýdlo s extraktem z gotukolových lístků, panenského kokosového oleje, olivového ovocného oleje, mandlového oleje, jojobového oleje, hroznového oleje, peprmintového oleje a extraktu z peprmintových a moringových lístků.\n" +
                "Neobsahuje žádné syntetické přísady ani umělá barviva.\n" +
                "Chandanalepa Gotukola Herbal Soap Vaši pokožku prokrví, má antioxidační účinky a omlazuje buňky. Pokožku zanechává svěží, vláčnou a mladistvou.\n" +
                "Na výrobu mýdla je použita výhradně Gotukola, kterou Chandanalepa pěstuje ve své organické zahradě.\n" +
                "Pouze pro vnější použití.",
            Size: "",
        },

        "CW0007": {
            Title: "CHANDANALEPA BEE’S HONEY",
            Tag: "Moisturizing body lotion",
            Description: "Hydratační tělové mléko je vyrobeno z přírodního včelího medu se 100% přírodním olivovým a mandlovým olejem.\n" +
                "Chandanalepa Bee’s Honey tělové mléko má mimořádné přírodní hydratační a antioxidační vlastnosti.\n" +
                "Tato jedinečná ayurvédská receptura Vaši pokožku omlazuje a zanechává ji svěží a pružnou. Krásně voní.",
            Size: "225ml",
        },

        "CW0011": {
            Title: "CHANDANALEPA JASMINE",
            Tag: "Moisturizing body lotion",
            Description: "Hydratační tělové mléko obohacené o veškeré výtažky Jasmínu, které omlazují pokožku a regenerují kožní buňky.\n" +
                "Chandanalepa Jasmine tělové mléko je jedinečná receptura s panenským kokosovým, olivovým a mandlovým olejem, který se snadno adsorbuje.\n" +
                "Hydratuje vaši pokožku po dobu 24 hodin. Díky každodenní aplikaci bude vaše pokožka zářivější a zdravější.",
            Size: "",
        },

        "CW0009": {
            Title: "CHANDANALEPA SANDALWOOD",
            Tag: "Moisturizing body lotion",
            Description: "Hydratační tělové mléko, obohacené o výtažky ze santalového dřeva a přírodní bylinné oleje.\n" +
                "Chandanalepa Sandalwood tělové mléko je vyrobené na základě ojedinělé receptury s účinky dvojité hydratace, která udržuje Vaši pokožku vláčnou a vyživenou po dobu více než 24 hodin.\n" +
                "Díky každodenní aplikaci bude vaše pokožka mladistvější a zdravější.",
            Size: "",
        },
        "CW0013": {
            Title: "CHANDANALEPA STRAWBERRY",
            Tag: "Moisturizing body lotion",
            Description: "Tělové mléko s jedinečnou kombinací 100% bylinných přírodních olejů namíchaných v extraktu z jahod bohatých na vitamín C a K.\n" +
                "Chandanalepa Strawberry tělové mléko je vyrobené na základě ojedinělé receptury s účinky dvojité hydratace, která udržuje Vaši pokožku vláčnou a vyživenou po dobu více než 24 hodin.\n" +
                "Díky každodenní aplikaci bude vaše pokožka mladistvější a zdravější.",
            Size: "",
        },
        "CW0017": {
            Title: "CHANDANALEPA BEAUTY CREAM",
            Tag: "Beautiful and Healthy Skin",
            Description: "Chandanalepa Beauty Krém obsahuje esenci a vůni santalového dřeva, čistého oleje ze santalového dřeva, Venivelu (žluté vinné révy), kokylové kůry, Suwandy Kottana, indické kurkumy, Velmadaty a dalších vysoce hodnotných domácích ajurvédských léčiv.\n" +
                "Byl vytvořen, aby Vaši pleť a obličej udělal krásný a zářivý.\n" +
                "Chrání Vaši pokožku před vysokým slunečním zářením nebo extrémním chladem. Udržuje pokožku zdravou tím, že ji hydratuje a účinně působí proti mykózám, pupínkům, černým a bílým skvrnám, zbarvení a ztmavnutí. Maximálě vyživuje pokožku a dodává jí zlatou barvu, zdravý a krásný vzhled.\n",
            Size: "",
        },

        "CW0019": {
            Title: "CHANDANALEPA HERBAL SCRUB",
            Tag: "Clear, Soft and Refreshing Skin",
            Description: "Unikátní ajurvédská receptura vytvořená s vysoce účinnou léčivou esencí santalového dřeva, Margosy, Feniklu, Kokumové kůry a indické kurkumy.\n" +
                "Chandanalepa Herbal Scrub obsahuje Pro-vitamín B5 a přírodní vitamín E, které působí na Vaši pokožku velmi blahodárně a vyživují ji.\n" +
                "Chandanalepa Herbal Scrub šetrně a bezpečně odstraňuje suchou, odumřelou kůži, čímž pleť činí zdravou, dodává jí zlatý odstín a obnovuje její ochranou funkci.\n" +
                "Chandanalepa Herbal Scrub je určen jak pro ženy, tak pro muže.",
            Size: "",
        },

        "CW0031": {
            Title: "CHANDANALEPA HAIR OIL",
            Tag: "Ayurvedic hair gold",
            Description: "Tento vlasový olej (zlato pro vlasy) se vyrábí podle tradiční ajurvédské receptury, využívané po staletí pro zářivé a zdravé vlasy ceylonských žen i mužů.\n" +
                "Chandanalepa Hair Oil v sobě obsahuje 100% přírodní panenský kokosový olej kombinovaný s 32 druhy bylin a koření jako jsou například - Nika, Skořice, Hřebíček, Wasavasi, Pískavice, atd ...\n" +
                "Chandanalepa Hair Oil zabraňuje vypadávání vlasů, předčasnému šedivění, vysoušení vlasů, pomáhá při bolestech hlavy. Rovněž uvolňuje duševní stres a navozuje klidný spánek.\n" +
                "Neobsahuje žádné umělé barvy ani chemikálie.",
            Size: "",
        },
        "CW0015": {
            Title: "CHANDANALEPA GOTUKOLA",
            Tag: "Anti-Dandruff Shampoo",
            Description: "Báječně vyživuje vlasy a pokožku hlavy, odstraňuje lupy a\n" +
                "zabraňuje přesychání pokožky hlavy.\n" +
                "Chandanalepa Gotukola Shampoo zanechá Vaše vlasy zdravé a lesklé.\n" +
                "Gotukola je známá a v Ajurvédě velmi oblíbená především jako “potrava pro mozek”, působí hojivě, protizánětlivě, podporuje krevní oběh, má antioxidační účinky a omlazuje buňky.\n" +
                "Na výrobu šampónu je použita výhradně Gotukola, kterou Chandanalepa pěstuje ve své organické zahradě.",
            Size: "",
        },
        "CW0021": {
            Title: "GINIRAJA HERBAL SPRAY",
            Tag: "Pain relieving",
            Description: "Giniraja Herbal Spray je Ayurvédský bylinný sprej, který napomáhá při bolesti svalů a kloubů, bolesti zad, artritidy, křečí či vyvrtnutí.\n" +
                "Je velmi populární i mezi sportovci pro rychlou úlevu při ztuhlosti svalů výronech nebo pohmožděninách.\n" +
                "Výrazná hřejivá terapie spreje velmi rychle působí při bolestech svalů.\n" +
                "Giniraja Herbal Spray – bylinný přípravek ze 12 druhů bylin a koření bez analgetických látek je šetrný k Vašemu organismu.\n" +
                "Praktická forma spreje se snadno nanáší na postižené místo, aniž byste si umazali ruce. Snadno se vtírá a rychle se vpije.\n" +
                "Pozor – opravdu hřeje!",
            Size: "",
        },
        "CW0020": {
            Title: "GINIRAJA HERBAL BALM",
            Tag: "Pain relieving",
            Description: "Giniraja Herbal Balm je Ayurvédský bylinný balzám, který napomáhá při bolesti svalů a kloubů, bolesti zad, artritidy, křečí či vyvrtnutí.\n" +
                "Je velmi populární i mezi sportovci pro rychlou úlevu při ztuhlosti svalů výronech nebo pohmožděninách.\n" +
                "Výrazná hřejivá terapie balzámu velmi rychle působí při bolestech svalů.\n" +
                "Giniraja Herbal Balm – bylinný přípravek ze 12 druhů bylin a koření bez analgetických látek je šetrný k Vašemu organismu.\n" +
                "Praktická forma malého balzámu je vždy po ruce, a můžete ho tak použít hned když je bolest v počátku. Snadno se vtírá a rychle se vpije.\n" +
                "Pozor – opravdu hřeje!",
            Size: "10g",
        },

        "CW0016": {
            Title: "CATARRH RELIEF OIL",
            Tag: "Chandanalepa",
            Description: "Tento prastarý ajurvédský bylinný olej Vám přinese úlevu od všech symptomů zánětu kataru, tedy horních cest dýchacích - rýma, nachlazení, ucpaný noc, bolest uší, bolest hlavy, špatný dech, svědění a pálení očí, kýchaní či zahlenění.\n" +
                "Usnadňuje vykašlávání, rozpouští hlen a uvolňuje ucpaný nos.\n" +
                "Catarrh Relief Oil v sobě kombinuje unikátní směs bylin a koření - lístky niky, kmín, zázvor, skořici, pepř, hřebíček, muškátový oříšek, wasa-vasi a další, které Vám přinesou okamžitou úlevu. Pomáhá také eliminovat stres, ulevuje mysli a pomáhá dobrému spánku. Obsahuje přírodní vůně.\n" +
                "Jeho mimořádná a příjemná aromaterapie Vám zklidní podrážděnou a zanícenou sliznici. Stačí nanést v oblasti nosu nebo kolem uší či můžete inhalovat. Nepálí.",
            Size: "50ml",
        },

        "CW0027": {
            Title: "LEVER AYUSH ANTI-CAVITY",
            Tag: "Clove Oil Toothpaste",
            Description: "Zubní pasta s hřebíčkovým olejem.\n" +
                "Bylinná zubní pasta je vyrobená na základě 5000 let staré receptury využívající moudrosti ayurvédské medicíny.\n" +
                "Lever Ayush Anti Cavity Toothpaste obsahuje léčivý hřebíčkový olej, který díky své silné antibakteriální kvalitě Vás zbaví bolesti zubů a kořenitá vůně dodá svěží dech.\n" +
                "Lever Ayush Anti Cavity Toothpaste obsahuje Dasanakhanti Choornan – unikátní ayurvédský zubní prášek, který zabraňuje krvácení a otokům dásní, bolesti zubů a tvorbě zubního kazu.\n" +
                "Používejte min. 2x denně\n" +
                "Skladujte na suchém a chladném místě.",
            Size: "120g",
        },
        "CW0025": {
            Title: "LEVER AYUSH WHITTENING",
            Tag: "Rock Salt Toothpaste",
            Description: "Zubní pasta s horskou solí.\n" +
                "Bylinná zubní pasta je vyrobená na základě 5000 let staré receptury využívající moudrosti ayurvédské medicíny.\n" +
                "Lever Ayush Whitening Toothpaste obsahuje horskou sůl, která je v ayurvédě známá jako nejzdravější forma soli a po staletí se využívá pro bělení zubů.\n" +
                "Lever Ayush Whitening Toothpaste obsahuje Arimedas Tailam – unikátní ayurvédská směs 38 bylin, která pomáhá dělat zuby silnější a zabraňuje zubnímu kazu.\n" +
                "Používejte min. 2x denně.\n" +
                "Skladujte na suchém a chladném místě.",
            Size: "120g",
        },

        "CW0022": {
            Title: "SIDDHALEPA BALM",
            Tag: "Ayurvedic Balm with Eucalyptus, Cinnamon Oil",
            Description: "Siddhalepa BALM je Ayurvédský bylinný balzám, který napomáhá při nachlazení, bolesti hlavy, migréně, bolesti zubů, kloubů při revmatických problémech a zmírňuje bolesti v období menstruace.\n" +
                "Je velmi populární i mezi sportovci pro rychlou úlevu při ztuhlosti svalů výronech nebo pohmožděninách. Hřejivá terapie balzámu velmi dobře působí při bolestech kloubů.\n" +
                "Siddhalepa BALM napomáhá při nachlazení a ulevuje od bolestí v krku. Má velmi příjemnou a svěží skořicovo- eukalyptovou vůni.\n" +
                "Aplikujte Siddhalepa BALM na postižená místa a vmasírujte do pokožky. Prohřátí pokožky koupelí nebo napařování urychlí proces zlepšení obtíží.\n" +
                "Udržujte postižená místa v teple a opakujte proces každé 3 hodiny.",
            Size: "50ml",
        },

        "CW0029": {
            Title: "LINK NATURAL SAMAHAN",
            Tag: "Natural Ayurvedic Instant Care",
            Description: "Samahan je 100% přírodní instantní ayurvédský nápoj. Jedná se o velmi koncentrovaný bylinný přípravek ve formě prášku, který se ředí vodou. Obsahuje účinnou kombinaci 14 léčivých bylin a blahodárně působícího koření, která vám okamžitě dodá energii, uleví při chřipce a nachlazení a pomůže snížit horečku.\n" +
                "Posiluje imunitní systém.\n" +
                "Složení je koncipováno tak, aby jednotlivé složky spolu působily synergicky a vzájemně si pomáhaly své účinky zvyšovat.\n" +
                "Samahan není lék, nepotlačuje imunitní systém ani příznaky nemocí. Naopak tělu pomáhá bojovat vůči vetřelcům a imunitní systém podporuje. Samahan vyniká svými protizánětlivými a analgetickými účinky. Zároveň také pomáhá snižovat teplotu, rozpouští hleny a usnadňuje vykašlávání. Bude se vám hodit při chřipce, nachlazení, bolestech hlavy, kašli a vůbec kdykoliv, kdy se budete cítit pod psa. Vhodný je jako prevence i při nemoci a únavě.\n" +
                "Samahan báječně působí jako přírodní „spalovač“ a dodává energii před fyzickou i psychickou zátěží. Složení:\n" +
                "Koriandr – semena, Zázvor - kořen, Černý pepř - plod, Dlouhý pepř - plod, Dřevo calumba - stonek, Premna herbacea - kořen, Evolvulus alsinoides – celá rostlina, Římský kmín - semeno, Adhathoda vasica - kořen, Kmín koptský - semeno, Alpinie lékařská - oddenek, Solanum xanthocarpum – celá rostlina, Lékořice lysá, Mollugo cerviana, Hedyotis corymbosa – celá rostlina, Cukrová třtina.\n" +
                "Použití:\n" +
                "Nalijte do hrnku 100–250 ml horké vody, vysypte do něj jeden sáček Samahanu, zamíchejte a vypijte. Nezalévejte Samahan vroucí vodou. Rozmíchat ho ale můžete i v jakékoliv jiné tekutině, například v mléku, rostlinném nápoji nebo kávě.\n" +
                "Samahan mohou užívat všechny věkové skupiny, jen dětem pod 5 let dávejte rozpuštěnou pouze polovinu sáčku. Při prevenci je doporučen 1 sáček denně, při léčbě nemoci podle potřeby 2–3 sáčky denně.",
            Size: "30ks",
        },
        "CW0023": {
            Title: "KOKOSOVÁ MISKA PREMIUM",
            Tag: "Natural Braun",
            Description: "100% přírodní kokosová miska Premium z ručně opracované kokosové skořápky, bez použití chemických nebo syntetických látek, dodá každému jídlu úplně jiný rozměr. Každá miska je originál svou velikostí, tvarem i opracováním a její význam je nejen věcný, ale i ekologický. Po opracování jsou čištěny, broušeny a leštěny panenským kokosovým olejem.\n" +
                "Koupí misky ušetříte atmosféru nebezpečného oxidu uhličitého, který se dostává do ovzduší při masivním spalování kokosových skořápek a má za následek globální oteplování.\n" +
                "Parametry: Objem cca 500 ml, průměr cca 15 cm, výška cca 10 cm.\n" +
                "Použití: Připravte si snídaňovou kaši, polévku, omáčku, salát, nebo jakýkoliv jiný pokrm a servírujte v kokosové skořápce! Misku můžete využít i pro přípravu pleťových masek, peelingů nebo jen jako dekoraci.\n" +
                "Údržba: Omyjte houbičkou a horkou vodou, nedávejte do myčky a do mikrovlnné trouby. Jednou za čas ošetřete kokosovým nebo jiným olejem pro delší trvanlivost.\n" +
                "Složení: 100% kokosová skořápka",
            Size: "1ks",
        },
    }

    static productsCode = [

        "CW0002",
        "CW0003",
        "CW0004",
        "CW0006",
        "CW0010",
        "CW0017",
        "CW0018",
        "CW0019",
        "CW0020",
        "CW0021",
        "CW0031",
        "CW0032",
        "CW0033",
        "CW0034",
        "CW0035",
        "CW0036",
        "CW0037",

    ];

    static productsOther = [
        "CW0001",
        "CW0005",
        "CW0007",
        "CW0008",
        "CW0009",
        "CW0011",
        "CW0012",
        "CW0013",
        "CW0014",
        "CW0015",
        "CW0016",
        "CW0022",
        "CW0023",
        "CW0024",
        "CW0025",
        "CW0026",
        "CW0027",
        "CW0028",
        "CW0029",
        "CW0030",

        "CW0038",
        "CW0900",
        "CW0901",
    ];


    constructor(props) {
        super(props);
        this.handleAddToCart = this.handleAddToCart.bind(this);

        
        let categoryId = null;

        if (props.match.params.id === undefined) {
            
        } else {
            categoryId = props.match.params.id;
        }
        

        this.state = {
            productsLoading: true,
            products: [],
            categoriesLoading: true,
            categories: [],
            cartUpdated: false,
            categoryId: categoryId,
            currentCategory: null,
            title: Product.title,
            redirect: null
        }
    }


    async componentDidMount() {
        
        if (this.state.categoryId === null) {
            await this.getProducts()
                .then(response => {
                    console.log(response);
                    this.setState({
                        products: response.data,
                        productsLoading: false
                    });
                })
                .catch(error  => {
                    if (errorServer(error)) {
                        return this.props.handleUserLoginAnnonym();
                    }
                    console.log('error' + error);
                });
        }
        else {
            await this.getProductByCategoryById(this.state.categoryId).then(async response => {
                if (response.data.length === 0) {
                    swal(
                        {
                            content:
                                <>
                                    <h3>V této kategorii nejsou žádné produkty</h3>
                                </>,
                            buttons: {
                                close: {
                                    text: "Zpět",
                                    className: "btn btn--none",
                                }

                            },
                        })
                        .then(result => {
                            
                            this.setState({
                                redirect: Product.url
                            });
                        });
                } else {
                    /*
                    await this.getCategoryById(this.state.categoryId).then(responseCategory => {
                        console.log(responseCategory);
                    })
                    */
                    await this.getCategoryById(this.state.categoryId).then(
                        async category => {
                            this.setState({
                                currentCategory: category,
                                products: response.data,
                                productsLoading: false,
                            });
                        }
                    );
                }
            })
                .catch(error  => {
                    if (errorServer(error)) {
                        return this.props.handleUserLoginAnnonym();
                    }
                    console.log('error' + error);
                });
        }
        
        
        
        await this.getCategories().then(response => {
            this.setState({
                categories: response.data, 
                categoriesLoading: false
            });
        });

        


    }

    async getProductByCategoryById(id) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).post(`/api/article/category/` + id);
    }
    
    async getCategoryById(id) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get(`/api/category/` + id);
    }
    
    async getCategories() {
        return axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get('/api/category/all');
    }

    async getProducts() {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).post('/api/article/all');
    }


    async handleAddToCart(item) {
        
        await showLoader();

        if (isAnnonym()) {
            swal(
                {
                    content:
                        <>
                            <h3>Pro přidání produktu do košíku se prosím přihlašte</h3>
                        </>,
                    buttons: {
                        login: {
                            text: "Přihlásit se"
                        },

                        close: {
                            text: "Zavřít",
                            className: "btn btn--none",
                        },
                    },
                }
            ).then(result => {
                if (result === "login") {
                    this.setState({
                        redirect: Login.url,
                    })
                }
            });
        } else {
            await addToCart(item.Code, 1).then(response => {
                this.props.handleAlert(
                    <div className="added-to-cart">
                        <h3>Zboží bylo přidáno do košíku</h3>
                        <p>{item.Name}</p>
                        <p>Množství: 1 {item.SpecificUnit}</p>
                    </div>
                );
                this.setState({
                    cartUpdated: true
                });
            });
        }
        await hideLoader();
    }

    getCategoriesContainer() {

        const items = this.state.categories;

        return (
            <>
                <h5 className="widget-title">Kategorie</h5>
                <ul className="cata-list shop-page">
                    {items.map(item => {
                        return (
                            <li>
                                <Link to={`/eshop/${item.Id}`} data-toggle="tooltip"
                                      data-placement="top" title="Detail">{item.Name}</Link>
                            </li>
                        )
                    })}
                </ul>
            </>
        );
    }

    static async getProductsApi(codeList) {

        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).post('/api/article/price', qs.stringify({
            codeList: codeList
        }));

    }

    getProductsContainer() {
        let items = this.state.products;

        return items.map(item => {
            return (
                <div key={item.Code} className="col-12 col-sm-6 col-lg-4">
                    <div className="single-product-area mb-50">
                        <Link className="product-thumbnail" to={`/produkt/${item.Code}`} data-toggle="tooltip"
                              data-placement="top" title="Detail">
                            <img src={getProductImage(item.Code)} alt={item.Code}/>
                            {/* <span className="product-tags">Novinka</span> */}
                            <div className="product-meta-data">
                                {/*}
                                <a href="#" data-toggle="tooltip" data-placement="top"
                                   title="Favourite"><FontAwesomeIcon icon={faHeart}/></a>
                                   */}
                            </div>
                        </Link>

                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <button disabled={item.Quantity <= 0} className="product-btn" onClick={() => (
                            this.handleAddToCart(item)
                            ///this.setState({cartUpdated: !this.state.cartUpdated})
                            //this.props.global.handleAddToCart(item.Code)
                        )} data-toggle="tooltip" data-placement="top" title="Přidat do košíku"><FontAwesomeIcon
                            icon={faShoppingCart}/></button>

                        <div className={`quantity-level ${item.Quantity <= 0 ? "nonavailable" : item.Quantity <= 3 ? "few" : "available"}`}>{item.QuantityLevel}</div>

                        <div className="product-desc text-center pt-2">
                            <Link to={`/produkt/${item.Code}`} className="product-title">{item.Name}</Link>
                        </div>
                    </div>
                </div>
            )
        })
    }


    render() {
        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect}/>
        }

        let products = this.state.productsLoading
            ? <LoadingItem title="Načítání produktů "/>
            : this.getProductsContainer();
        
        let categories = this.state.categoriesLoading
        ? <LoadingItem title="Načítání kategorií" />
        : this.getCategoriesContainer();
        

        return (
            <>
                <MetaTags>
                    <title>{this.state.title}</title>
                    <meta name="description" content=""/>
                    <meta property="og:title" content={this.state.title}/>
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')}/>
                </MetaTags>

                <Heading1 title={this.state.title} image="our-product"/>

                <section className="shop-area section-padding-0-100">
                    <div className="container">

                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3">
                                
                                
                                <div className="single-widget-area">
                                    {categories}
                                </div>
                                

                                <Cart global={this.state} 
                                      handleUser={this.props.handleUser}
                                      handleAlert={this.props.handleAlert}
                                      handleUserLoginAnnonym={this.props.handleUserLoginAnnonym}
                                      cartUpdated={this.state.cartUpdated} 
                                      product/>

                            </div>

                            <div className="col-12 col-md-8 col-lg-9">
                                <div className="row">
                                    {products}
                                </div>

                                {/*}
                                <nav>
                                    <ul className="pagination mb-0 mt-50">
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#"><i
                                            className="fa fa-angle-right"></i></a></li>
                                    </ul>
                                </nav>
                                */}
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}
