import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import {Contact} from "./Contact";
import MetaTags from "react-meta-tags";

export class Team extends Component {
    static displayName = Team.name;
    static title = "Ceylon Way Team"
    static url = "/ceylon-way-team";

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }


    render() {
        
        return (
            <div>
                <MetaTags>
                    <title>{Team.title}</title>
                    <meta name="description" content=""/>
                    <meta property="og:title" content={Team.title}/>
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')}/>
                </MetaTags>

                <Heading1 title={Team.title} image="about-bg"/>

                <section className="section-team">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2><span>Spolupracujte</span> s námi</h2>
                            <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="join-us">
                                    <h3 className="heading heading--3">Proč se stát člen Ceylon Way Team?</h3>

                                    <p>Společnost Ceylon Way s.r.o. je výhradní dovozce a velkoobchod ajurvédských
                                        přípravků
                                        pro zdraví a krásu. Zájemce o koupi produktů musí být registrovaný uživatel u
                                        společnosti Ceylon Way s.r.o. – člen klubu Ceylon Way Team.</p>

                                    <p>Přidejte se k Ceylon Way Team a užijte si všech výhod, které náš klub
                                        přináší:</p>
                                </div>

                                <div className="item item--large">
                                    <h4>Pomáhejte sobě i ostatním</h4>
                                    <ul>
                                        <li>Chcete pečovat o zdraví své i blízkých?</li>
                                        <li>Máte obchod, kde byste chtěli prodávat produkty Ceylon Way?</li>
                                        <li>Chcete zároveň získat příjemný výdělek?</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6">


                                <div className="item">
                                    <h4>Žádné závazky, pouze výhody!</h4>
                                    <ul>
                                        <li>Registrace zcela <b>ZDARMA</b> – žádné poplatky, ani vstupní balíčky.</li>
                                        <li>Možnost nakupovat výrobky přímo od dovozce.</li>
                                        <li>Okamžitá <b>sleva</b> z maloobchodní ceny až 30% – nakupujete za velkoobchodní ceny.</li>
                                        <li>Získáte přístup k dalším produktovým slevám nad rámec <b>členských</b> cen.</li>
                                        <li><b>Odměna</b> za doporučení nových zákazníků – členů klubu.</li>
                                        <li>Získáte veškeré produktové a marketingové materiály.</li>
                                    </ul>
                                </div>

                                <div className="item">
                                    <h4>Čím více nakupujete – tím větší slevy</h4>
                                    <ul>
                                        <li>Každým registrovaným nákupem <b>získáváte body</b>, které se měsíčně přepočítávají na <b>slevy</b> či odměny.
                                        </li>
                                        <li>Jako poradce (OSVČ) získáváte za nákupy body, které se přepočítávají na <b>provizi</b>, která je vám automaticky vyplácena na účet nebo ji směníte za zboží.
                                        </li>
                                    </ul>
                                </div>

                                <div className="item">
                                    <h4>Získejte pro Ceylon Way další přátele – vydělávejte společně!</h4>
                                    <ul>
                                        <li>Přivedete-li do Ceylon Way Team další <b>aktivní</b> členy, získáte body i za jejich nákupy.
                                        </li>
                                        <li>Čím víc přátel do své sítě zaregistrujete – tím <b>větší</b> budete mít slevy nebo provize.</li>
                                        <li>Pečujte o své <b>zdraví</b> a zároveň získejte příjemný výdělek.</li>
                                    </ul>
                                </div>

                                <p>Podrobné informace najdete v článku: <span>Jak se stát členem CEYLON WAY TEAM A VÝHODY ČLENSTVÍ.</span></p>

                                <p>Pro další informace volejte: 608 136 126 nebo nám napište na hello@ceylon-way.cz</p>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    Contact.getTeamSection()
                }
            </div>
        );
    }
}
