import React, { Component } from 'react';

export class LoadingItem extends Component {
    static displayName = LoadingItem.name;


    render () {
        return (
            <div className="col-12">
                <div className="wrapper-loader">
                    <div className="wrapper wrapper--loader">
                        <div className="loader loader--item"></div>
                    </div>
                    <div className="heading heading--loading">{this.props.title}</div>
                </div>
            </div>
        );
    }
}
