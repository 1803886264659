import React, {Component} from 'react';
import axios from "axios";
import App from "../../App";
import qs from "qs";
import {hideLoader, scrollTop, showLoader} from "./components/utils/Utils";
import {getAdminToken, isAnnonym} from "./User";
import {Heading1} from "./components/Heading1";
import MetaTags from "react-meta-tags";
import {Link, Redirect} from "react-router-dom";
import {Product} from "./Product";
import {Login} from "./components/Login";

export class Register extends Component {
    static displayName = this.name;
    static title = "Registrace"
    static url = "/registrace";


    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            redirectp: false,
        }
    }

    componentDidMount() {
    }

    resetForm() {
        document.getElementById("register-form").reset();
    }

    async handleSubmit(event) {
        event.preventDefault();
        
        const data = new FormData(event.target);

        let username = data.get("username");
        let name = data.get("name");
        let surname = data.get("surname");
        let email = data.get("email");
        let password = data.get("password");
        let passwordRepeat = data.get("passwordRepeat");
        let recommender = data.get("recommender");
        let comment = '';
        
        if (recommender !== '') {
            comment = `sponsorType="membercard" sponsorValue="${recommender}"`;
        }
        
        if (password !== passwordRepeat) {
            this.props.handleAlert(
                <div className="">
                    <h3>Hesla se neshodují</h3>
                </div>
            );
            return;
        }

        showLoader();

        await axios.create({
            baseURL: App.apiUrl,
            headers: {
                'Authorization': 'Bearer ' + await getAdminToken()
            }
        }).post('/api/user/create', qs.stringify({
            Login: username,
            Email: email,
            FirstName: name,
            LastName: surname,
            Password: password,
            PasswordHash: '',
            recommender: recommender,
            Comment: comment,
        })).then((response) => {

            //console.log(response);
            this.props.handleAlert(
                <div className="">
                    <h3>Už jen potvrďte registraci v emailu a můžete nakupovat</h3>
                </div>
            );

            localStorage.setItem('user', (JSON.stringify(response.data)));
            scrollTop();
            this.resetForm();
            this.setState({
                redirectp: true,
            })
        })
            .catch(error => {
                //console.log(error.response);
                if (error.response !== undefined) {

                    if (error.response.status === 400) {
                        this.props.handleAlert(
                            <div className="">
                                <h3>Neplatné jméno nebo heslo</h3>
                            </div>
                        );
                    }

                    if (error.response.status === 500) {
                        this.props.handleAlert(
                            <div className="">
                                <h3>{error.response.data.ExceptionMessage}</h3>
                            </div>
                        );
                    }
                }
                
            });
        hideLoader();
    }


    render() {
        
        if (this.state.redirectp) {
            return (
                <Redirect to={Login.url} />
            )
        }

        if (!isAnnonym()) {
            return (
                <Redirect to={Product.url}/>
            )
        }
        
        return (
            <>
                <MetaTags>
                    <title>{Register.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={Register.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={Register.title}/>

                <section className="section-padding-0-100">
                    <div className="container">

                        <h2>Nový uživatel</h2>

                        <form id="register-form" className="mb-5" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="username" pattern="[a-zA-Z0-9]{2,}" placeholder="Uživatelské jméno *" required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="name" placeholder="Jméno *"
                                                   required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="surname"
                                                   placeholder="Příjmení *" required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="email" className="form-control" name="email"
                                                   placeholder="Email *" required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" name="password"
                                                   placeholder="Heslo *" minLength={8} required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" name="passwordRepeat"
                                                   placeholder="Heslo znovu *" minLength={8} required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="number" className="form-control" name="recommender"
                                                   placeholder="ID doporučitele - nepovinné pole"/>
                                        </div>
                                        
                                        <div className="col-md-12 mb-30">
                                            <label className="form-check-label">
                                                <input className="mr-1" type="checkbox" name="privacy" required />
                                                Souhlasím se zpracováním osobních údajů *
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        
                                        <div className="col-md-12 mb-30">
                                            <label className="form-check-label">
                                                <input className="mr-1" type="checkbox" name="privacy" required />
                                                Souhlasím o obchodními podmínkami společnosti Ceylon Way s.r.o. *
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        
                                        <div className="col-12">
                                            <button type="submit" className="btn btn--primary">Registrovat</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <p>Již máte účet? <Link onClick={this.closeModal} to={Login.url} title="Přihlásit se"><span>Přihlásit se</span></Link></p>

                    </div>
                </section>
            </>
        );
    }
}
