import React, {Component} from 'react';
import {NavMenu} from "./components/NavMenu";
import {Footer} from "./components/Footer";
import {scrollTop, showPreloader} from "./components/utils/Utils";
import swal from "@sweetalert/with-react";
import {TitleProvider} from "./components/TitleContext";


export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        //this.props.global.handleAddToCart = this.props.global.handleAddToCart.bind(this);
        //this.handleAddToCart = this.handleAddToCart.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.showBreadcrumbP = this.showBreadcrumbP.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        
        this.state = {
            global: props.global,
            setTitle: this.setTitle,
            showBreadcrumbP: this.showBreadcrumbP,
            user: null,
            showAlert: false,
            contentAlert: "",
        }
    }

    componentDidMount() {
        scrollTop();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        //showPreloader();
        //console.log(prevProps);
        
        //this.props.handleUser();
        
        
        if (this.state.showAlert === true) {
            swal({
                content: this.state.contentAlert,
                buttons: {
                    close: {
                        text: "Zavřít",
                        className: "btn btn--none",
                    },
                },
            });
            this.state.showAlert = false;
            this.state.contentAlert = "";

            /*
            this.setState({
                showAlert: false,
                contentAlert: "",
            });
            
             */
        }
    }
    

    showBreadcrumbP(showp) {
        this.setState({
            showBreadcrumb: showp
        });
    }

    handleAlert(content) {
        this.setState({
            showAlert: true,
            contentAlert: content,
        });
    }
    setTitle(title) {
        this.setState({
            title: title,
        })
    }
    

    render() {

        /*
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                //handleTitle: this.handleTitle,
                //handleAddToCart: this.handleAddToCart,
            });
        });
         */
        

        return (
            <TitleProvider>
                <NavMenu global={this.state} 
                         handleUserLoginAnnonym={this.props.handleUserLoginAnnonym} 
                         handleAlert={this.props.handleAlert} 
                         handleUserLogout={this.props.handleUserLogout} 
                         setTitle={this.setTitle} 
                         handleUserLogin={this.props.handleUserLogin} 
                         handleUser={this.props.handleUser} userLogged={this.props.userLogged}
                />
                {this.props.children}
                <Footer setTitle={this.setTitle}/>
            </TitleProvider>
        );
    }
}

