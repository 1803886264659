import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapPin, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {shopInfo} from "./utils/Utils";

export class Company extends Component {
    static displayName = this.name;
    

    getContainer() {
        let shop = shopInfo();
        
        if (this.props.footer) {
            return (
                <div className="contact-info">
                    <p><i className="fa fa-map-pin"
                          aria-hidden="true"></i><span>{shop.address}</span></p>
                    <p><i className="fa fa-envelope"
                          aria-hidden="true"></i><span>{shop.email}</span></p>
                    <p><i className="fa fa-phone"
                          aria-hidden="true"></i><span>{shop.phone}</span></p>
                </div>
            )
        }
        
        if (this.props.contact) {
            return (
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="single-information-area text-center mb-100 wow fadeInUp"
                             data-wow-delay="100ms">
                            <div className="contact-icon">
                                <FontAwesomeIcon icon={faMapPin} />
                            </div>
                            <h5>Adresa</h5>
                            <h6>{shop.address}</h6>
                        </div>
                    </div>


                    <div className="col-12 col-md-4">
                        <div className="single-information-area text-center mb-100 wow fadeInUp"
                             data-wow-delay="500ms">
                            <div className="contact-icon">
                                <FontAwesomeIcon icon={faPhoneAlt} />
                            </div>
                            <h5>Telefon</h5>
                            <h6>{shop.phone}</h6>
                        </div>
                    </div>


                    <div className="col-12 col-md-4">
                        <div className="single-information-area text-center mb-100 wow fadeInUp"
                             data-wow-delay="1000ms">
                            <div className="contact-icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <h5>Email</h5>
                            <h6>{shop.email}</h6>
                        </div>
                    </div>

                </div>
            )
        }
        
        return (
            <>
                <a href={`mailto:${shop.email}`} data-toggle="tooltip" data-placement="bottom"
                   title={shop.email}>
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <span> {shop.email}</span>
                </a>
                <a href={`tel:${shop.phone}`} data-toggle="tooltip" data-placement="bottom"
                   title={shop.phone}>
                    <FontAwesomeIcon icon={faPhoneAlt}/>
                    <span> {shop.phone}</span>
                </a>
            </>
        )
    }

    render() {
        return (
            this.getContainer()
        );
    }
}
