import React, {Component} from 'react';
import {LoadingItem} from "./components/loading/LoadingItem";
import axios from "axios";
import {getToken} from "./User";
import App from "../../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faShoppingCart,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import {Link, NavLink, Redirect} from "react-router-dom";
import {getProductImage, getProductTextInCart, hideLoader, showLoader} from "./components/utils/Utils";
import {Order} from "./Order";
import MetaTags from "react-meta-tags";
import {getCartContentFromServer} from "./components/utils/Cart";
import {errorServer} from "./components/error/ServerError";

export class Cart extends Component {
    static displayName = this.name;
    static title = "Košík"
    static url = "/kosik";

    constructor(props) {
        super(props);

        this.state = {
            loadingCart: true,
            cart: [],
            sum: 0,
            productCount: 0,
            updatedp: false,
            redirectp: false,
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        
        await this.props.handleUser();
        //console.log('cart componentWillReceiveProps');

        this.state.updatedp = true;
        await this.getCartContent();
    }

    async componentDidMount() {
        await this.props.handleUser();

        await this.getCartContent();
    }

    async addToCart(code, quantity = 1) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get(`/api/shoppingcart/add?code=${code}&quantity=${quantity}&tagInt=&tagStr=`);
    }

    async productInc(code) {
        await showLoader();

        await this.addToCart(code).then(response => {
            this.getCartContent();
        });

        await hideLoader();
    }

    async productDec(code) {
        await showLoader();

        await this.addToCart(code, -1).then(response => {
            this.getCartContent();
        });

        await hideLoader();
    }

    clearCart() {
        axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get('/api/shoppingcart/clear').then(response => {
            if (response.status === 200) {
                this.setState({cart: [], productCount: 0, sum: 0});
            }
        })

        this.props.handleAlert(
            <h3>
                Košík byl vyprázdněn
            </h3>
        );
    }

    async removeItemCart(item) {

        await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).get(`/api/shoppingcart/delete/${item.Id}`).then(response => {
            this.getCartContent();
        })

        this.props.handleAlert(
            <h3>
                Produkt byl odstraněn z košíku
            </h3>
        );
    }

    async getCartContent() {
        await getCartContentFromServer()
            .then(response => {
                if (response.status === 200) {
                    let sum = 0;
                    let productCount = 0;

                    response.data.forEach(item => {
                        sum += (item.SumPriceVat);
                        productCount += item.Quantity;
                    });

                    this.setState({
                        cart: response.data,
                        loadingCart: false,
                        sum: sum,
                        productCount: productCount
                    });

                } else {
                    //console.log('Cart error ' + response);
                }
            })
            .catch(error => {
                if (errorServer(error)) {
                    return this.props.handleUserLoginAnnonym();
                }
                
                if (error.response !== undefined) {
                    //console.log('Chyba spojení se serverem v košíku ' + error.response.data.ExceptionMessage);
                } else {
                    //console.log("Error response undefined cart");
                }
            });
    }


    getCartItems() {
        let items = this.state.cart;

        let content = () => {
            if (this.state.productCount !== 0) {
                return (
                    <>
                        <div className="product-show">
                            <h6>Celkem {this.state.productCount} {getProductTextInCart(this.state.productCount)}
                            </h6>
                        </div>

                        <div className="cart-table clearfix">

                            <table className="table">
                                <tbody>
                                {items.map(item => {
                                    return (
                                        <tr key={item.Code}>
                                            <td className="cart_product_img">
                                                <Link to={`/produkt/${item.Code}`} data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Detail">
                                                    <img src={getProductImage(item.Code)} alt=""/>
                                                </Link>
                                            </td>
                                            <td className="cart_product_desc">
                                                <p>{item.Name}</p>
                                                <h6>{item.Quantity} x {Number((item.Price * ((item.VatRate / 100) + 1)).toFixed(1))} Kč</h6>
                                            </td>

                                            <td className="cart_product_quantity">
                                                <button onClick={() => this.productInc(item.Code)}>
                                                    <FontAwesomeIcon icon={faAngleUp}/>
                                                </button>
                                                <button onClick={() => this.productDec(item.Code)}>
                                                    <FontAwesomeIcon icon={faAngleDown}/>
                                                </button>
                                            </td>

                                            <td className="pro-close">
                                                <button onClick={() => this.removeItemCart(item)}>
                                                    <FontAwesomeIcon icon={faWindowClose}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="cart-summary d-flex align-items-center justify-content-between">
                            <div className="sub-total">
                                <h6>Celkem</h6>
                            </div>
                            <div className="total-price">
                                <h6>{this.state.sum}Kč</h6>
                            </div>
                        </div>

                        {getCartBtn()}
                    </>
                )
            }

            return (
                <p>Váš košík je prázdný</p>
            )
        }

        let getCartBtn = () => {
            if (this.state.productCount !== 0) {
                return (
                    <>
                        <button className="btn btn--primary checkout-btn mt-30 w-100" onClick={() => {
                            {window.location.href=Order.url}
                            /*
                            this.setState({
                                redirectp: true,
                            })
                            
                             */
                        }}>
                        Přejít k
                            objednání</button>
                        {/*
                        <Link to={Order.url} className="btn btn--primary checkout-btn mt-30 w-100">Přejít k
                            objednání</Link>
                            */}
                        {/*
                        <button className="btn btn--danger" onClick={() => this.clearCart()}>
                            <FontAwesomeIcon icon={faWindowClose}/>
                            Vyprázdnit košík
                        </button>
                        */}
                    </>
                )
            }
        }

        return (
            <div className="shopping-cart mb-50">
                <h5 className="mb-30">Nákupní košík</h5>
                {content()}
            </div>
        )
    }


    cartContent() {
        if (this.props.product) {
            return (
                <>
                    {this.getCartItems()}
                </>
            )
        }


        return (
            <div className="container">
                <MetaTags>
                    <title>{Cart.title}</title>
                    <meta name="description" content=""/>
                    <meta property="og:title" content={Cart.title}/>
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')}/>
                    <meta name="robots" content="noindex, nofollow"/>
                </MetaTags>

                {this.getCartItems()}
            </div>
        )
    }


    render() {
        
        if (this.state.redirectp) {
            return <Redirect to={Order.url} />
        }

        if (this.props.nav) {
            return (
                <div className="cart-icon">
                    <NavLink to={Cart.url} className='js-nav-link'>
                        <FontAwesomeIcon icon={faShoppingCart}/>
                    </NavLink>
                </div>
            )
        }

        let cartContent = this.state.loadingCart
            ? <LoadingItem title="Načítání košíku"/>
            : this.cartContent();


        return (
            <>
                {cartContent}
            </>

        );
    }
}
