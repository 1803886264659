import React, {Component} from 'react'
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {Link, Redirect} from "react-router-dom";
import axios from "axios";
import App from "../../../App";
import {hideLoader, scrollTop, showLoader} from "./utils/Utils";
import qs from "qs";
import {getToken, getUser, isAnnonym, setLocalStorageUser} from "../User";
import {Heading1} from "./Heading1";
import {Register} from "../Register";
import MetaTags from "react-meta-tags";
import {Product} from "../Product";


export class Login extends Component {
    // Context state
    static title = "Přihlásit se"
    static url = "/prihlasit";


    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            showModal: false,
            loading: false,
            error: null,
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        scrollTop();
    }


    openModal() {
        this.setState({
            showModal: true
        });
    }

    closeModal() {
        this.setState({
            showModal: false,
            error: null,
        });
    }

    resetForm() {
        document.getElementById("login-form").reset();
    }

    handleSubmit(event) {
        event.preventDefault();

        //var data = $(this).serialize(); //all input variables
        //let submit = $("button[type=submit]");
        //let btnContent = submit.text();
        //let btnContent = "Přihlásit se";
        
        
        showLoader();
        const data = new FormData(event.target);
        let username = data.get('username');
        let password = data.get('password');

        axios.post(App.apiUrl + '/api/token',
            qs.stringify({
                username: username,
                password: password,
                grant_type: 'password',
                ExternalLogin: false,
                CurrentToken: getToken(),

            }),
            {
                headers: {
                    //'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then((response) => {
                //console.log(response);
                //console.log(response.data);
                //console.log(response.status);
                
                setLocalStorageUser(response.data, false);
                
                scrollTop();
                this.resetForm();
                this.closeModal();
                
                this.props.handleUserLogin();
                hideLoader();
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        this.props.handleAlert(
                            <div className="">
                                <h3>Neplatné jméno nebo heslo</h3>
                            </div>
                        );
                    }

                    if (error.response.status === 500) {
                        this.props.handleAlert(
                            <div className="">
                                <h3>{error.response.ExceptionMessage}</h3>
                            </div>
                        );
                    }
                }

                //console.log(error.response);
                //console.log(error);
                //alert('Vyskytla se neočekávaná chyba');
                //this.closeModal();
                
                hideLoader();
            });
    }


    render() {
        if (!isAnnonym() && this.props.redirect) {
            return this.props.redirect;
        }

        if (getUser() && !isAnnonym()) {
            return <Redirect to={Product.url} />
        }
        
        if (this.props.modal) {
            return (
                <>
                    <button onClick={this.openModal} className="">
                        <FontAwesomeIcon icon={faUserAlt}/>
                        <span> Přihlásit se</span>
                    </button>

                    <Modal show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Přihlášení</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <form id="login-form" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control" name="username"
                                               placeholder="Uživatelské jméno" required/>
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="password" className="form-control" name="password" placeholder="Heslo"
                                               required/>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn--primary">
                                            Přihlásit se
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </Modal.Body>
                        <Modal.Footer className="justify-content-between">
                            <p>Nemáte ještě účet? <Link onClick={this.closeModal} to={Register.url} title="Registrace"><span>Zaregistrujte se</span></Link></p>

                            <button className="btn btn--none" onClick={this.closeModal}>
                                Zavřít
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
        
        return (
            <>
                <MetaTags>
                    <title>{Login.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={Login.title} />
                    <meta property="og:image" content={require('../../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={Login.title}/>

                <section className="section-padding-0-100">
                    <div className="container">

                        <h2>Přihlášení</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <form id="login-form" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="username"
                                                   placeholder="Uživatelské jméno" required/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" name="password" placeholder="Heslo"
                                                   required/>
                                        </div>

                                        <div className="col-md-12">
                                            <p>Nemáte ještě účet? <Link onClick={this.closeModal} to={Register.url} title="Registrace"><span>Zaregistrujte se</span></Link></p>
                                        </div>
                                            
                                        <div className="col-12">
                                            <button type="submit" className="btn btn--primary">
                                                Přihlásit se
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                
                                
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

