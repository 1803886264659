import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
import {Login} from "./components/Login";
import axios from "axios";
import App from "../../App";
import {getToken} from "./User";
import queryString from 'query-string';
import {LoadingItem} from "./components/loading/LoadingItem";
import {Contact} from "./Contact";

export class ConfirmRegistration extends Component {
    static displayName = this.name;
    static title = "Potvrzení registrace"
    static url = "/potvrzeni-registrace";

    constructor(props) {
        super(props);
        let param = window.location.search
        let parsedParams = queryString.parse(param);
        let error = false;
        
        if (parsedParams.hash === undefined) {
            error = true;
        }
        
        this.state = {
            hash: parsedParams.hash,
            loading: true,
            error: error,
            verified: false,
        }
    }
    
    async confirmRegistration(registrationHash) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + await getToken()}
        }).get('/api/registration/confirm/' + registrationHash);
    }
    
    async componentDidMount() {
        if (!this.state.verified && this.state.loading) {
            await this.confirmRegistration(this.state.hash)
                .then(result => {
                    if (result.data === false) {
                        this.setState({
                            loading: false,
                            verified: true,
                            //verified: false,
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            verified: true,
                        });
                    }
                })
                .catch(error => {
                    /*
                    this.setState({
                        loading: false,
                        verified: false,
                    });
                    
                     */
                });
        }
        
    }

    getContainer() {
        let msg = 
            <>
                <h2>Právě jsme Vás úspěšně zaregistrovali!</h2>
                <h3>Vítejte ve světě prémiových ajurvédských produktů u nás v Ceylon Way <span>💚</span></h3>
            </>
        
        if (!this.state.verified) {
            msg =
            <>    
                <h2>Omlouváme se, email se nepodařilo ověřit, pro více informací nás kontaktujte</h2>
                <Link className="btn btn--primary" onClick={this.closeModal} to={Contact.url} title="Kontakt">Kontaktujte nás</Link>
            </>
        }
        
        return (
            <>
                
                <section className="section-padding-0-100">
                    <div className="container">
    
                        <div className="section-heading text-center">
                            {msg}
                        </div>
    
                        {this.state.verified ? <Link className="btn btn--primary" onClick={this.closeModal} to={Login.url} title="Přihlásit se">Přihlásit se</Link> : ""}
    
                    </div>
                </section>
            </>
        )
    }
    
    render() {
        
        if (this.state.error) {
            return (
                <>
                    <Heading1 title={ConfirmRegistration.title}/>
                    
                    <section className="section-padding-0-100">
                        <div className="container">
    
                            <div className="section-heading heading--3 text-center">
                                <h2>Došlo k chybě při načtení stránky</h2>
                            </div>
    
                        </div>
                    </section>
                </>
            );
        }

        let container = this.state.loading
            ? <LoadingItem title="Probíhá ověření emailu"/>
            : this.getContainer();

        //console.log(this.props.location.search);
        return (
            <>
                <MetaTags>
                    <title>{ConfirmRegistration.title}</title>
                    <meta name="description" content="" />
                    <meta name="robots" content="nofollow, noindex" />
                    <meta property="og:title" content={ConfirmRegistration.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>

                <Heading1 title={ConfirmRegistration.title}/>

                {container}
            </>
        );
    }
}
