import React, {Component} from 'react';
import {shopInfo} from "./components/utils/Utils";
import {Heading1} from "./components/Heading1";

export class Cookie extends Component {
    static displayName = Cookie.name;
    static title = "Prohlášení cookies"
    static url = "/prohlaseni-cookies";

    getCookies() {
        let shop = shopInfo();
        
        return (
            <>
                <header className="wrapper__heading wow fadeInDown">
                    <h2 className="heading heading--2">Informace o souborech cookies</h2>
                    <p className="subheading--2"></p>
                </header>
                <div className="row">
                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading pad-bottom--4 wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">Informační povinnost
                                správce
                                osobních údajů</h3>
                            <div></div>
                        </header>

                        <p>Správce osobních údajů tímto, v souladu s nařízením Evropského parlamentu a Rady (EU)
                            č.
                            2016/679, obecné nařízení o ochraně osobních údajů, informuje Vás, jako subjekt
                            údajů, o
                            níže uvedených skutečnostech.</p>
                        <p>Správcem osobních údajů je {shop.company}</p>
                        <p>e-mail: {shop.email}</p>
                        <p>tel: {shop.phone}</p>

                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading pad-bottom--4 wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">Co jsou soubory
                                cookies</h3>
                            <div></div>
                        </header>

                        <p>Cookies jsou malé textové soubory, které internetové stránky ukládají na váš počítač
                            nebo
                            mobilní zařízení v okamžiku, kdy tyto stránky začnete využívat. Stránky si tak na
                            určitou
                            dobu zapamatují vaše preference a úkony, které jste na nich provedli (např.
                            přihlašovací
                            údaje, jazyk, velikost písma a jiné zobrazovací preference), takže tyto údaje pak
                            nemusíte
                            zadávat znovu a přeskakovat z jedné stránky na druhou. </p>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading pad-bottom--4 wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">Druhy souborů
                                cookies</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li><strong>Nezbytně nutné:</strong> Tyto cookies jsou nezbytné k tomu, abyste se
                                mohli
                                pohybovat po internetových stránkách a používat speciální funkce stránek.
                            </li>
                            <li><strong>Výkonnostní:</strong> Tyto cookies umožňují internetové stránce uložit
                                uživatelské preference, jako například jazykové nastavení. Zajišťují uživateli
                                vylepšené
                                užívání internetové stránky.
                            </li>
                            <li><strong>Funkční:</strong> Tyto cookies shromažďují informace o chování
                                uživatele.
                                Tyto
                                informace jsou součástí zpráv, jejichž účelem je zlepšení webových stránek.
                                Všechna
                                nashromážděná data jsou anonymní.
                            </li>
                            <li><strong>Cílené a reklamní:</strong> Tyto cookies se používají pro reklamy, která
                                je
                                relevantní pro uživatele a jeho zájmy. Dále používáme cookies např. Google
                                Analytics
                                pro
                                analýzu návštěvnosti.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading pad-bottom--4 wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">Použití souborů
                                cookies</h3>
                            <div></div>
                        </header>

                        <p>Prostřednictvím souborů cookies o získáváme a zpracováváme informace o tom, jak naši
                            internetovou stránku používáte a o Vaší aktivitě na stránkách. Soubory cookies
                            používáme
                            na
                            základě oprávněného zájmu a uděleného souhlasu, a to za účelem řádného užívání
                            internetových
                            stránek, poskytování funkcí stránky a sociálních médií a analýze návštěvnosti
                            internetových
                            stránek.</p>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading pad-bottom--4 wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">Souhlas s ukládáním cookies</h3>
                            <div></div>
                        </header>

                        <p>Používání souborů cookies lze nastavit pomocí Vašeho internetového prohlížeče.
                            Používáním
                            těchto internetových stránek souhlasíte s ukládáním souborů cookies. Cookies můžete
                            zablokovat v nastavení svého webového prohlížeče.</p>

                    </div>
                </div>
            </>
        )
    }

    render() {

        return (
            <>
                <Heading1 title={Cookie.title}/>

                <section className="section-padding-0-100">
                    <div className="container">
                        {this.getCookies()}
                    </div>
                </section>
            </>
        );
    }
}
