import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import {NavItem, NavLink} from "reactstrap";
import NavMenuItems from "./NavMenuItems";
import {Postage} from "../Postage";
import {ReturnGood} from "../ReturnGood";
import {Company} from "./Company";

export class Footer extends Component {
    static displayName = Footer.name;

    getFooterItemLinks() {
        return [
            Postage, ReturnGood
        ]
    }

    makeLinks(items) {

        return (
            <>
                {items.map((item, index) => {
                    return (
                        <NavItem key={index} className='js-nav-item'>
                            <NavLink tag={Link}
                                     className='js-nav-link'
                                     to={item.url}>{item.title}</NavLink>
                        </NavItem>
                    );

                })}
                <li><a href={require('../../../assets/document/obchodni-podminky.pdf')} target="_blank" rel="noopener noreferrer">Obchodní podmínky</a></li>
                <li><a href={require('../../../assets/document/vseobecne-podminky.pdf')} target="_blank" rel="noopener noreferrer">Všeobecné podmínky</a></li>
            </>
        )
    }


    render() {

        const req = require('../../../assets/images/bg/footer.jpg');
        const style = {
            backgroundImage: 'url(' + req + ')',
        }

        return (
            <footer className="footer-area">

                <div className="main-footer bg-img bg-overlay section-padding-80-0" style={style}>
                    <div className="container">
                        <div className="row">


                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-widget mb-80">
                                    <div className="foo-logo d-block mb-30">
                                        <img src={require('../../../assets/images/logo/logo-white.svg')} alt="Ceylon Way" width="180"/>
                                    </div>
                                    <p>Věříme, že i vy, společně s námi, objevíte kouzlo ajurvédy, která je zároveň i
                                        životním stylem.</p>
                                    <Company footer/>
                                </div>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-widget mb-80">
                                    <h5 className="widget-title">Informace</h5>

                                    <nav className="footer-widget-nav">
                                        <ul>
                                            {this.makeLinks(this.getFooterItemLinks())}

                                        </ul>
                                    </nav>
                                </div>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-widget mb-80">
                                    <h5 className="widget-title">Rychlá navigace</h5>


                                    <nav className="footer-widget-nav">
                                        <ul>
                                            <NavMenuItems setTitle={this.props.setTitle}/>
                                        </ul>
                                    </nav>


                                </div>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="footer-widget mb-80">
                                    <h5 className="widget-title">Zůstaňte s námi v kontaktu</h5>

                                    <div className="social-info">
                                        <a href="https://www.facebook.com/ceylonwaycz" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon className="icon" icon={faFacebookF}/>
                                            <span>Facebook</span>
                                        </a>
                                        <a href="https://www.instagram.com/ceylonway/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon className="icon" icon={faInstagram}/>
                                            <span>Instagram</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="copywrite-area">
                    <div className="container">
                        <div className="copywrite-text">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script>
                                        All rights reserved | <a href="https://www.zsolution.cz" target="_blank" rel="noopener noreferrer"> Zsolution s.r.o.</a>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-nav">
                                        <nav>
                                            <NavMenuItems setTitle={this.props.setTitle} personalItemsP={true}/>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
