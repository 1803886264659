import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import {shopInfo} from "./components/utils/Utils";
import MetaTags from "react-meta-tags";

export class ReturnGood extends Component {
    static displayName = this.name;
    static title = "Vrácení zboží"
    static url = "/vraceni-zbozi";

    getText() {
        let shop = shopInfo();

        return (
            <>
                <p>Zboží je možno vrátit do 14-ti dnů a to odesláním obyčejnou zásilkou na
                    adresu {shop.invoiceAddress}. </p>
                <p>Zboží musí být vráceno nepoškozené a nepoužité ve stavu, v jakém bylo kupujícím převzato.</p>
                <p>Se zbožím prosím zašlete vyplněný níže uvedený <a href={require('../../assets/document/reklamacni-protokol.xlsx')} target="_blank" rel="noopener noreferrer">„Formulář pro odstoupení od kupní smlouvy“</a>.</p>
                <p>Kupní cena včetně přepravného (pokud bylo fakturováno) bude poukázána kupujícímu do 30 dnů od
                    obdržení vráceného zboží. Prodávající je dále oprávněn snížit vrácenou částku o částku odpovídající
                    poškození nebo opotřebení vráceného zboží.</p>
                <p>V oznámení o odstoupení je kupující povinen uvést bankovní spojení účtu, na který má být poukázáno
                    vrácení kupní ceny, jinak bude částka poukázána na náklady kupujícího poštovní poukázkou na adresu
                    uvedenou v objednávce. V oznámení o odstoupení se doporučuje uvést rovněž číslo objednávky nebo
                    číslo faktury pro urychlení vyřízení odstoupení.</p>
            </>
        )
    }


    render() {

        return (
            <>
                <MetaTags>
                    <title>{ReturnGood.title}</title>
                    <meta name="description" content=""/>
                    <meta property="og:title" content={ReturnGood.title}/>
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')}/>
                </MetaTags>

                <Heading1 title={ReturnGood.title}/>

                <section className="section-padding-0-100">
                    <div className="container">

                        <h2>Jak na vrácení zboží</h2>

                        {this.getText()}
                    </div>
                </section>
            </>
        );
    }
}
