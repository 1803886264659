import React, {Component} from 'react';
import {Product} from "./Product";
import {LoadingItem} from "./components/loading/LoadingItem";
import {addToCart, getProductImage, hideLoader, showLoader} from "./components/utils/Utils";
import {Heading1} from "./components/Heading1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import NumericInput from 'react-numeric-input';
import axios from "axios";
import App from "../../App";
import {getToken, isAnnonym} from "./User";
import swal from "@sweetalert/with-react";
import {Login} from "./components/Login";
import MetaTags from "react-meta-tags";
import Helmet from "react-helmet";
import {Redirect} from "react-router-dom";
import {errorServer} from "./components/error/ServerError";

export class ProductDetail extends Component {
    static displayName = ProductDetail.name;
    static title = "Product detail"
    static url = "/produkt";


    constructor(props) {
        super(props);

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
        let productCode = null;

        if (props.match === undefined) {
            productCode = props.code;
        } else {
            productCode = props.match.params.id;
        }

        this.state = {
            productCode: productCode,
            productQuantity: 0,
            productQuantityLevel: 0,
            productLoading: true,
            product: null,
            quantity: 1,
            redirect: null,
        }
    }

    async componentDidMount() {
        await this.getProduct(this.state.productCode)
            .then(response => {

                if (response.data.length === 0) {
                    swal(
                        {
                            content:
                                <>
                                    <h3>Produkt nebyl nalezen</h3>
                                </>,
                            buttons: {
                                close: {
                                    text: "Vybrat jiný produkt",
                                    className: "btn btn--none",
                                }

                            },
                        })
                        .then(result => {
                            //console.log(result);
                            this.setState({
                                redirect: Product.url
                            });
                        });
                } else {
                    this.setState({
                        product: response.data[0],
                        productQuantity: response.data[0].Quantity,
                        productQuantityLevel: response.data[0].productQuantityLevel,
                        productLoading: false,
                    })
                }
            })
            .catch(error => {
                if (errorServer(error)) {
                    return this.props.handleUserLoginAnnonym();
                }

                if (error.response === undefined) {

                    return swal(
                        {
                            content:
                                <>
                                    <h3>Produkt nebyl nalezen</h3>
                                </>,
                            close: {
                                text: "Vybrat jiný produkt",
                                className: "btn btn--none",
                            },
                        })
                        .then(result => {
                            this.setState({
                                redirect: Product.url
                            });
                        });

                }

            });
    }

    async getProduct(code) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).post(`/api/article`, {
            codeList: [code],
        });
    }

    handleChangeQuantity(quantity) {
        if (quantity !== null) {
            this.setState({
                quantity: quantity,
            });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        await this.handleAddToCart(this.state.product, data.get('quantity'))
    }

    async handleAddToCart(product, quantity = 1) {
        await showLoader();
        
        if (isAnnonym()) {
            swal(
                {
                    content:
                        <>
                            <h3>Pro přidání do košíku se prosím přihlašte</h3>
                        </>,
                    buttons: {
                        login: {
                            text: "Přihlásit se"
                        },

                        close: {
                            text: "Zavřít",
                            className: "btn btn--none",
                        },
                    },
                }
            ).then(result => {
                if (result === "login") {
                    this.setState({
                        redirect: Login.url
                    })
                }
            })
        }
        else if (quantity < 1) {
            this.props.handleAlert(
                <h3>Množství musí být kladné</h3>
            );
        }
        else {
            await addToCart(product.Code, quantity).then(response => {
                this.setState({
                    cartUpdated: true,
                    productQuantityLevel: this.state.productQuantityLevel - quantity,
                });

                this.props.handleAlert(
                    <div className="added-to-cart">
                        <h3>Zboží bylo přidáno do košíku</h3>
                        <p>{product.Name}</p>
                        <p>Množství: {quantity} ks</p>
                    </div>
                );
            });
        }
        

        await hideLoader();
    }

    getProductContainer() {

        return (
            <>
                <MetaTags>
                    <title>{this.state.product.Name}</title>
                    <meta name="description" content={this.state.product.Description}/>
                    <meta property="og:title" content={this.state.product.Name}/>
                    <meta property="og:image" content={getProductImage(this.state.product.Code)}/>
                </MetaTags>

                <Heading1 title={this.state.product.Name} links={[Product]}/>

                <section className="shop-area section-padding-0-100">
                    <div className="container">

                        <div className="row align-items-center">

                            <div className="col-12 col-lg-6">
                                <div className="mb-100">
                                    <div className="section-heading">
                                        <h2>{this.state.product.Name}</h2>
                                        <p>{this.state.product.Tag}</p>
                                        <img src={require("../../assets/images/core/decor.png")} alt=""/>
                                    </div>
                                    <p>{this.state.product.Description}</p>
                                </div>

                                <p>{this.state.product.Size}</p>

                                <form id="add-product-to-cart" onSubmit={this.handleSubmit}>
                                    <div className="d-flex justify-content-between">
                                        <div className="price">Cena: {this.state.product.PriceVat} Kč</div>
                                        <div className="">
                                            <span>Dostupnost: </span>
                                            <span className={`quantity-level ${this.state.product.Quantity <= 0 ? "nonavailable" : this.state.product.Quantity <= 3 ? "few" : "available"}`}>{this.state.product.QuantityLevel}</span>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="quantity-input-container">
                                                <NumericInput onChange={this.handleChangeQuantity} 
                                                              name="quantity"
                                                              className="form-control" placeholder="Množství" min={0}
                                                              max={this.state.product.Quantity}
                                                              value={this.state.quantity}
                                                              disabled={this.state.product.Quantity <= 0}
                                                              required/>
                                                <span className="quantity">z {this.state.product.Quantity} ks</span>
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <button disabled={this.state.product.Quantity <= 0} className="btn btn--primary" type="submit" title="Přidat do košíku">
                                                <FontAwesomeIcon icon={faShoppingCart}/>
                                                <span>Do košíku</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="wow fadeInUp" data-wow-delay="900ms">

                                </div>

                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="mb-100">
                                    <img src={getProductImage(this.state.product.Code)} alt={this.state.product.Name}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </>
        )
    }


    getLdJson() {
        let data = {
            '@context': 'http://www.schema.org',
            '@type': 'Product',
            'name': this.state.product.Name,
            'image': getProductImage(this.state.product.Code),
            'description': this.state.product.Description,
            'sku': this.state.product.Code,
            'offers': {
                '@type': 'Offer',
                'Price': this.state.product.PriceVat,
                'PriceCurrency': 'CZK',
                'Availability': this.state.product.Quantity > 0 ? "https://schema.org/InStock" : "https://schema.org/SoldOut",
            }
        }

        return JSON.stringify(data);
    }


    render() {
        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect}/>
        }

        let product = this.state.productLoading
            ? <LoadingItem title="Načítání ... "/>
            : this.getProductContainer();

        let ldJson = this.state.productLoading
            ? ''
            : this.getLdJson();


        return (
            <>
                <Helmet>
                    <script className='structured-data-list' type="application/ld+json">{ldJson}</script>
                </Helmet>

                {product}
            </>
        );
    }
}
