import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import MetaTags from "react-meta-tags";

export class HowToBuy extends Component {
    static displayName = this.name;
    static title = "Jak nakupovat"
    static url = "/jak-nakupovat";

    constructor(props) {
        super(props);
        this.state = {
            global: props.global,
        }
    }

    componentDidMount() {
    }


    render() {
        
        return (
            <>
                <MetaTags>
                    <title>{HowToBuy.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={HowToBuy.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={HowToBuy.title}/>

                <section className="section-padding-0-100">
                    <div className="container">

                        <h2>{HowToBuy.title}</h2>

                    </div>
                </section>
            </>
        );
    }
}
