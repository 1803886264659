import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class Heading1 extends Component {
    static displayName = Heading1.name;

    constructor(props) {
        super(props);
        
        this.state = {
            
        }

    }


    render() {
        
        const image = this.props.image ? this.props.image : "universal";
        
        const imageRequire = require(`../../../assets/images/bg/${image}.jpg`);
        const style = {
            backgroundImage: 'url(' + imageRequire + ')',
        }

        let links = () => {
            if (this.props.links !== undefined) {
                return this.props.links.map(item => {
                    return (
                        <li key={item.title} className="breadcrumb-item">
                            <Link to={item.url}>{item.title}</Link>
                        </li>
                    )
                })
            }
        }
        
        

        return (

            <>
                <div className="breadcrumb-area bg-img bg-overlay" style={style}>
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12">
                                <div className="breadcrumb-text">
                                    <h1>{this.props.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="famie-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Domů</Link>
                                </li>
                                {links()}
                                
                                <li className="breadcrumb-item active" aria-current="page">{this.props.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </>
        );

    }
}
