import React, {Component} from 'react';
import {getAboutUs, getIcons} from "./components/utils/Utils";
import {Heading1} from "./components/Heading1";
import {owlCarousel} from "./components/utils/Utils";
import MetaTags from "react-meta-tags";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";


export class About extends Component {
    static displayName = About.name;
    static title = "O nás"
    static url = "/o-nas";

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        owlCarousel();
    }


    render() {
        const req1 = require('../../assets/images/bg/contact.jpg');
        const style1 = {
            backgroundImage: 'url(' + req1 + ')',
        }


        return (
            <div>
                <MetaTags>
                    <title>{About.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={About.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={About.title} image="about-bg"/>

                <section className="famie-benefits-area pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="benefits-thumbnail mb-50">
                                    <img src={require('../../assets/images/bg/2.jpg')} alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            {getIcons()}
                        </div>
                    </div>
                </section>

                <section className="about-us-area">
                    <div className="container">
                        <div className="section-heading">
                            <p>o nás</p>
                            <h2><span>Náš</span> příběh</h2>
                            <img src={require('../../assets/images/core/decor.png')} alt=""/>
                        </div>
                        
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8">
                                <div className="about-us-content">
                                    {getAboutUs()}
                                </div>
                            </div>

                            <div className="col-12 col-md-4 mb-5">
                                <img src={require('../../assets/images/logo/logo.svg')} width="350" alt="Ceylon Way"/>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="testimonial-area bg-img bg-overlay section-padding-100" style={style1}></section>


                <section className="section-padding-100">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2><span>Naši</span> partneři</h2>
                            <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="text-bigger">
                                    <div className="mb-5">
                                        <div className="d-flex align-items-center">
                                            <div className="mb-5">
                                                <img src={require('../../assets/images/logo/108-palms.png')} alt="108 Palms Resort"/>
                                            </div>
                                            <div className="">
                                                <h3>108 Palms Beach Resort, Srí Lanka</h3>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex">
                                            <p>Resort se nachází na osamělé panenské pláži v tropické zahradě s palmami , přímo uprostřed zeleně.
                                                Evropský standard ubytování se snoubí se stylem dovolené Robinsona Cruso.
                                                Soukromí našich návštěvníků je pro nás na předním místě, což kapacita resortu uzpůsobená pro 34 osob naprosto splňuje.
                                                Každý  si zde užije  opravdový klid a pohodu.
                                                Český manažer a místní kuchyně se postarají o autentické nezapomenutelné zážitky.
                                                <a href="https://youtu.be/RafP3aJWxTU" target="_blank" rel="noopener noreferrer"> Podívejte se sami</a></p>
                                        </div>
                                        <div className="social-info about--social-info">
                                            <div className="d-inline-block mr-30">
                                                <a href="https://www.facebook.com/108palms" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon className="icon" icon={faFacebookF}/>
                                                    <span>Facebook</span>
                                                </a>
                                            </div>

                                            <div className="d-inline-block mr-30">
                                                <a href="https://www.instagram.com/srilankaresort/" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon className="icon" icon={faInstagram}/>
                                                    <span>Instagram</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="mb-5">

                                        <div className="d-flex align-items-center">
                                            <div className="mb-5">
                                                <img src={require('../../assets/images/logo/tesanee.png')} alt="Masážní salon Tesanee, Průhonice"/>
                                            </div>
                                            <div className="">
                                                <h3>Salon Tesanee</h3>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex">
                                            <p>Salon Tesanee vznikl v roce 2014 s jednoduchou vizí – přímo v srdci Průhonic dopřát klientům luxus klidu, relaxace a péče o zdraví z rukou profesionálních masérek.
                                                Naši klienti nejvíce oceňují profesionalitu a úsměv všech lidí z týmu Tesanee, klid, intimitu, soukromí a také bezplatné parkování přímo před vstupem do salonu.
                                                Každý měsíc pro klienty připravujeme překvapení v podobě masáží vhodných podle ročních období, slevy v rámci happy hours, partnerské masáže a dárkové vouchery…</p>
                                        </div>
                                        <div className="social-info about--social-info">
                                            <div className="d-inline-block mr-30">
                                                <a href="https://www.facebook.com/thajskemasazepruhonice" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon className="icon" icon={faFacebookF}/>
                                                    <span>Facebook</span>
                                                </a>
                                            </div>

                                            <div className="d-inline-block mr-30">
                                                <a href="https://www.instagram.com/tesanee_masaze/" target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon className="icon" icon={faInstagram}/>
                                                    <span>Instagram</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="single-slide">
                                        <div className="testi-text d-flex">
                                            <p>Největší výrobce ajurvédské kosmetiky na Srí Lance.
                                                Nejzkušenější výrobce ajurvédské kosmetiky v zemi s více než 26 letou zkušeností.
                                                Leader na trhu v ajurvédském kosmetickém průmyslu na Srí Lance.
                                                Jsme držitelem vyznamenání Leeds USA Gold Certified Cosmetics Facility in SL .
                                                Máme certifikát ISO 14001 EMS.
                                                1/3 námi spotřebované energie pochází ze slunečního záření.
                                                Ani kapka námi recyklované vody nepřijde na zmar, využíváme je v našich zahradách.
                                                Potravinový odpad, je přeměněn na kompost, který se využívá jako hnojivo, pro naši kultivaci Aloe Vera.
                                                Veškerý další odpad, včetně polyetylenu a papíru, je také recyklován.</p>
                                        </div>
                                        <div className="testimonial-thumbnail-name d-flex align-items-center">
                                            <div className="testimonial-thumbnail">
                                                <img src={require('../../assets/images/logo/chandanalepa.png')}alt="Chandanalepa by Sanjewaka Ayurveda Ltd."/>
                                            </div>
                                            <div className="testimonial-name">
                                                <h3>Chandanalepa by Sanjewaka Ayurveda</h3>
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding-100 section-chandanalepa">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2>O Chandanalepa</h2>
                            <img src={require('../../assets/images/core/decor2.png')} alt="Decor"/>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="d-flex flex-column h-100">
                                <h3 className="heading heading--3 heading--color">Chandanalepa by Sanjewaka Ayurveda Ltd.</h3>
                                    
                                    <div className="block-chandanalepa text-bigger">
                                        <ul>
                                            <li>Největší výrobce ajurvédské kosmetiky na Srí Lance.</li>
                                            <li>Nejzkušenější výrobce ajurvédské kosmetiky v zemi s více než 26 letou zkušeností.</li>
                                            <li>Leader na trhu v ajurvédském kosmetickém průmyslu na Srí Lance.</li>
                                            <li>Držitel vyznamenání Leeds USA Gold Certified Cosmetics Facility in SL.</li>
                                            <li>Držitel certifikátu ISO 14001 EMS.</li>
                                            <li>1/3 spotřebované energie pochází ze slunečního záření.</li>
                                            <li>Ani kapka recyklované vody nepřijde na zmar, využívá se v organických zahradách.</li>
                                            <li>Potravinový odpad, je přeměněn na kompost, který se využívá jako hnojivo, pro kultivaci Aloe Vera.</li>
                                            <li>Veškerý další odpad, včetně polyetylenu a papíru, je také recyklován.</li>
                                        </ul>
                                    </div>
                                
                                <div className="mt-auto">více na: <a href="https://www.chandanalepa.lk" target="_blank" title="Chandanalepa" rel="noopener noreferrer">www.chandanalepa.lk</a></div>
                                </div>
                            </div>

                            <div className="col-md-6 order-md-first">
                                <img src={require('../../assets/images/bg/sanjeewaka.jpg')} alt="Sanjeewaka" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding-100 section-ayurveda">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2>O Ajurvédě</h2>
                            <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="heading heading--3 heading--color">Sanskrtský výraz Ajurvéda se překládá jako „znalost života“.</h3>
                                
                                <div className="text-bigger">
                                    <p>Principy této ajurvédské moudrosti nám připomínají, že celá síť života je složitě protkána. Jinými slovy řečeno - <b>všechno souvisí se vším.</b></p>
                                    <p>Ajurvéda je holistický (celostní) systém, vytvořený před více než 2.500 lety na Srí Lance a v Indii.</p>
                                    <p>Základem je učení, že naše zdraví je závislé na rovnováze mysli, těla a ducha. Klade také důraz na umění harmonizace vnitřního a vnějšího prostředí. Hlavním cílem je především prevence, ne boj proti nemoci. Ajurvéda je více zaměřená na příčiny nemoci než následky zdravotních problémů.</p>
                                    
                                    <div className="row pb-5">
                                        <div className="col-md-6">
                                            <p>Ajurvéda vychází z představy, že vesmír se skládá z <b>pěti elementů:</b></p>
                                            <div className="block-ayurveda">
                                                <img className="ayurveda-icon" src={require('../../assets/images/icon/ajurveda.svg')} alt="Ajurvéda" width="50" />
                                                <ul className="counter">
                                                    <li>Vayu (vzduch)</li>
                                                    <li>Jala (voda)</li>
                                                    <li>Aakash (éter / prostor)</li>
                                                    <li>Prithvi (země)</li>
                                                    <li>Teja (oheň)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Těchto pět elementů formuje tři základní tělesné tipy, které kontrolují základní fyziologické funkce v lidském těle. <b>Jsou to:</b></p>
                                            <div className="block-ayurveda">
                                                <img className="ayurveda-icon" src={require('../../assets/images/icon/body-care.svg')} alt="Lidské tělo" width="50" />
                                                <ul className="counter">
                                                    <li>Vata dóša</li>
                                                    <li>Pitta dóša</li>
                                                    <li>Kapha dóša zvané jako “tridóšas”</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <p>Ajurvéda učí, že se lidské tělo se skládá ze sedmi druhů tkání. Rasa (tekutiny), Meda (tuk a pojivé tkáně), Rakta (krev), Asthi (kosti), Majja (kostní dřeň), Mamsa (svaly)
                                        Shukra (semeno). Dále tři odpadní produkty (Malas), což je Purisha (stolice), Mutra (moč) and Sweda (pot).</p>
                                    
                                    <p>Vata má na starost buněčný transport, elektrolytickou rovnováhu, eliminuje odpadní produkty a jejich důsledek - suchost organismu.</p>
                                    <p>Pitta reguluje tělesnou teplotu, koordinaci očních nervů a pocit hladu a žízně. Tepelné podmínky zhoršují funkci Pitty.</p>
                                    <p>Kapha roste s konzumací sladkého a tučného jídla a zajiš’tuje dostatek kloubních tekutin pro správnou funkci kloubů.</p>
                                    
                                    <p>Štěpné (katabolické) procesy v těle řídí Vata, metabolismus Pitta a Kapha. Pro zdraví je nutné udržovat rovnováhu mezi těmito dóšami. Každá nerovnováha vede k nemoci.</p>
                                    
                                    <p>Posláním Ceylon Way je dát možnost těm, kteří toto učení šíří, obdivují, uznávají, žijí anebo se chtějí začít seznamovat, koupit si ajurvédské produkty, které fungují jako prevence anebo umí pomoci při vznikajícím problému.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
