import React, {Component} from 'react';
import {showLoader, hideLoader} from "./components/utils/Utils";
import {Company} from "./components/Company";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Heading1} from "./components/Heading1";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import $ from "jquery";
import MetaTags from "react-meta-tags";

export class Contact extends Component {
    static displayName = Contact.name;
    static title = "Kontakt"
    static url = "/kontakt";

    constructor(props) {
        super(props);
        this.state = {
            sendedp: false,
            formMessage: 'Email nebyl odeslán',
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    resetForm() {
        document.getElementById("contact-form").reset();
    }

    handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        let submit = $("button[type=submit]");
        //let btnContent = submit.text();
        let btnContent = "Odeslat";
        showLoader(submit);


        fetch('/api/contact-form', {
            method: 'POST',
            body: data,
        })
            .then((res) => {
                if (res.ok) {
                    this.setState({
                        sendedp: true,
                        formMessage: "Email byl úpspěšně odeslán",
                    });
                    this.resetForm();

                    this.props.handleAlert(
                        <div className="alert">
                            <h3>Email byl úspěšně odeslán</h3>
                            <p>Brzy se Vám ozveme</p>
                        </div>
                    );
                } else {
                    this.props.handleAlert('Email se nepodařilo odeslat, zkuste to za chvíli znovu');
                }
            })
            .catch(e => {
                this.props.handleAlert('Email se nepodařilo odeslat, zkuste to za chvíli znovu');
            })
            .finally(() => {
                hideLoader(submit, btnContent);
            });
        ;
    }

    static getTeamList() {
        const personArray = [
            {
                name: "Dana Němec ",
                job: "zahraniční obchod a strategie",
                phone: "+420 604 294 777",
                mail: "dana.nemec@ceylon-way.cz",
                image: "nemec",
                facebook: "https://www.facebook.com/dana.mrazkova.921",
                instagram: "https://www.instagram.com/mrazkova.dana1"
            },
            {
                name: "Adolf Vlk",
                job: "obchod a distribuce",
                phone: "+420 608 136 126",
                mail: "adolf.vlk@ceylon-way.cz",
                image: "vlk",
                facebook: "https://www.facebook.com/profile.php?id=100015587402963",
                instagram: "https://www.instagram.com/adolfvlk",
            },
            {
                name: "Blanka Šímová",
                job: "finance",
                phone: "+420 603 223 322",
                mail: "blanka.simova@ceylon-way.cz",
                image: "simova",
                facebook: "https://www.facebook.com/blanka.simova.585",
                instagram: "https://www.instagram.com/blanka.simova.585",
            },
            {
                name: "Shan",
                job: "Ayurvedic specialist, Srí Lanka",
                phone: "+94 76 652 6655",
                mail: "shan@ceylon-way.cz",
                image: "shan",
                facebook: null,
                instagram: null,
            },
        ];

        return (
            personArray.map(item => {
                return (
                    <div key={item.image} className="col-12 col-sm-6 col-lg-3">
                        <div className="single-team-member mb-100 wow fadeInUp" data-wow-delay="100ms">
                            <div className="team-img">
                                <img src={require(`../../assets/images/team/${item.image}.jpg`)} alt={item.name}/>
                                <div className="team-social-info">
                                    {item.facebook !== null ? <a className="icon" href={item.facebook} data-toggle="tooltip" data-placement="right" title="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook}/></a> : "" }
                                    {item.instagram !== null ? <a className="icon" href={item.instagram} data-toggle="tooltip" data-placement="right" title="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a> : ""}
                                    <p>{item.phone}</p>
                                    <a href={`mailto:${item.mail}`}>{item.mail}</a>
                                </div>
                            </div>
                            <div className="team-member-info">
                                <h5>{item.name}</h5>
                                <h6>{item.job}</h6>
                            </div>
                        </div>
                    </div>
                )
            })
        );
    }

    static getTeamSection() {
        return (
            <section className="team-member-area section-padding-100-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <p>poznej náš tým</p>
                                <h2><span>Jsme tu</span> a rádi Vám pomůžeme</h2>
                                <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {Contact.getTeamList()}
                    </div>
                </div>
            </section>
        )
    }

    render() {
        return (

            <>
                <MetaTags>
                    <title>{Contact.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={Contact.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>
                
                <Heading1 title={Contact.title} image="contact"/>

                <section className="contact-info-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="section-heading text-center">
                                    <p>kontaktní informace</p>
                                    <h2><span>Nejlepší cesta</span> jak nás kontaktovat</h2>
                                    <img src={require('../../assets/images/core/decor2.png')} alt=""/>
                                </div>
                            </div>
                        </div>


                        <Company contact/>

                        <div className="c-border"></div>
                    </div>
                </section>

                {Contact.getTeamSection()}

                <section className="contact-area section-padding-100-0">
                    <div className="container">
                        <div className="row justify-content-between">


                            <div className="col-12 col-lg-5">
                                <div className="contact-content mb-100">

                                    <div className="section-heading">
                                        <p>napište nám</p>
                                        <h2><span>Spojte se</span> s námi</h2>
                                        <img src={require('../../assets/images/core/decor.png')} alt=""/>
                                    </div>

                                    <div className="contact-form-area">
                                        <form id="contact-form" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" name="name"
                                                           placeholder="Vaše jméno" required/>
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="email" className="form-control" name="email"
                                                           placeholder="Váš Email" required/>
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" className="form-control" name="subject"
                                                           placeholder="Předmět zprávy" required/>
                                                </div>
                                                <div className="col-12">
                                                    <textarea name="message" className="form-control" cols="30"
                                                              rows="10" placeholder="Zpráva"></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn--primary">
                                                        <FontAwesomeIcon icon={faEnvelope}/>
                                                        <span>Odeslat</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 offset-lg-1">
                                <div className="contact-content mb-100">

                                    <div className="section-heading">
                                        <p>Sledujte nás</p>
                                        <h2>Zůstaňte s námi v <span>kontaktu</span></h2>
                                        <img src={require('../../assets/images/core/decor.png')} alt=""/>
                                    </div>

                                    <div className="social-info contact--social-info">
                                        <a href="https://www.facebook.com/ceylonwaycz" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon className="icon" icon={faFacebookF}/>
                                            <span>Facebook</span>
                                        </a>
                                        <a href="https://www.instagram.com/ceylonway/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon className="icon" icon={faInstagram}/>
                                            <span>Instagram</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            {/*}
                            <div className="col-lg-6">
                                <div className="contact-maps mb-100">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28313.28917392649!2d-88.2740948914384!3d41.76219337461615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880efa1199df6109%3A0x8a1293b2ae8e0497!2sE+New+York+St%2C+Aurora%2C+IL%2C+USA!5e0!3m2!1sen!2sbd!4v1542893000723" allowFullScreen></iframe>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
