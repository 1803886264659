import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";
import axios from "axios";
import App from "../../App";
import {getToken} from "./User";
import queryString from 'query-string';
import {LoadingItem} from "./components/loading/LoadingItem";
import {Contact} from "./Contact";
import {Cart} from "./Cart";
import {appendScript} from "./components/utils/Utils";
import {Product} from "./Product";

export class GoPay extends Component {
    static displayName = this.name;
    static title = "Potvrzení platby"
    static url = "/gopay/potvrzeni-platby";

    constructor(props) {
        super(props);
        let param = window.location.search
        let parsedParams = queryString.parse(param);
        let error = false;

        if (parsedParams.Order === undefined || parsedParams.id === undefined) {
            error = true;
        }

        this.state = {
            order: parsedParams.Order,
            id: parsedParams.id,
            loading: true,
            error: error,
            verified: false,
        }
    }

    async confirmPayment(order) {
        return await axios.create({
            baseURL: App.apiUrl,
            headers: {'Authorization': 'Bearer ' + await getToken()}
        }).get('/api/gopay/paymentstate?order=' + order);
    }
    
    

    async componentDidMount() {
        appendScript(('https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js'), false);
        
        
        if (!this.state.verified && this.state.loading) {
            
            if (this.state.loading) {
                setInterval(() =>  {
                     this.confirmPayment(this.state.order)
                        .then(result => {
                            if (result.status === 200 && result.data !== null) {
                                this.setState({
                                    loading: false,
                                    verified: true,
                                });
                            }
                        });
                }, 4000);

            }
        }

    }

    getContainer() {
        let msg =
            <>
                <h2>Děkujeme. Objednávka <span>{this.state.order}</span> byla zaplacena.</h2>
                <Link className="btn btn--primary" to={Product.url} title="Naše produkty">Naše produkty</Link>
            </>

        if (!this.state.verified) {
            msg =
                <>
                    <h2>Omlouváme se, ale platba nebyla zaplacena, pro více informací nás kontaktujte</h2>
                    <Link className="btn btn--primary" to={Contact.url} title="Kontakt">Kontaktujte nás</Link>
                </>
        }

        return (
            <>

                <section className="section-padding-0-100">
                    <div className="container">

                        <div className="section-heading text-center">
                            {msg}
                        </div>

                    </div>
                </section>
            </>
        )
    }

    render() {

        if (this.state.error) {
            return (
                <>
                    <Heading1 title={GoPay.title}/>

                    <section className="section-padding-0-100">
                        <div className="container">

                            <div className="section-heading heading--3 text-center">
                                <h2>Omlouváme se, došlo k chybě při načtení stránky</h2>
                            </div>

                        </div>
                    </section>
                </>
            );
        }

        let container = this.state.loading
            ? <LoadingItem title="Probíhá platba"/>
            : this.getContainer();

        //console.log(this.props.location.search);
        return (
            <>
                <MetaTags>
                    <title>{GoPay.title}</title>
                    <meta name="description" content="" />
                    <meta name="robots" content="nofollow, noindex" />
                    <meta property="og:title" content={GoPay.title} />
                    <meta property="og:image" content={require('../../assets/images/logo/logo.svg')} />
                </MetaTags>

                <Heading1 title={GoPay.title} links={[Cart]}/>

                {container}
            </>
        );
    }
}
