export const errorServer = function (error) {
    
    if (error.response !== undefined) {
        if (error.response.status === 500) {
            return true;
        }
    }
    
    return false;
}

export const checkServerError = function (error) {
    
    
}