import React, {Component} from 'react';
import {Heading1} from "./components/Heading1";
import {shopInfo} from "./components/utils/Utils";

export class PersonalData extends Component {
    static displayName = PersonalData.name;
    static title = "Ochrana osobních údajů"
    static url = "/ochrana-osobnich-udaju";
    
    getPersonalData() {
        let shop = shopInfo();
        
        return (
            <>
                <header className="wrapper__heading wow fadeInDown">
                    <h2 className="heading heading--2">Podmínky ochrany osobních údajů</h2>
                    <p className="subheading--2">Nakládání s osobními údaji</p>
                </header>

                <div className="row">
                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">1. Základní
                                ustanovení</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>
                                Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady
                                (EU)
                                2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a
                                o
                                volném
                                pohybu těchto údajů (dále jen: „GDPR”) je {shop.company},
                                IČ: {shop.ic} se sídlem {shop.invoiceAddress} (dále jen: „správce“).
                            </li>

                            <li>
                                <p><strong>Kontaktní údaje správce jsou následující:</strong></p>

                                <ul>
                                    <li>Adresa: {shop.invoiceAddress}</li>
                                    <li>Email: {shop.email}</li>
                                    <li>Telefon: {shop.phone}</li>
                                </ul>
                            </li>

                            <li>
                                Dle čl. 4 odst. 1 GDPR se osobními údaji rozumí veškeré informace o
                                identifikované
                                nebo
                                identifikovatelné fyzické osobě („vy“ nebo „subjekt údajů“); identifikovatelnou
                                fyzickou
                                osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména
                                odkazem
                                na
                                určitý identifikátor, například jméno, identifikační číslo, lokační údaje,
                                síťový
                                identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické,
                                genetické,
                                psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.
                            </li>

                            <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
                        </ol>

                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">2. Zákonný důvod
                                zpracování
                                osobních údajů</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>Zákonným důvodem zpracování osobních údajů je plnění smlouvy mezi Vámi a
                                správcem
                                podle
                                čl. 6 odst. 1 písm. b) GDPR. (dále jen “Plnění smlouvy”) - zpracování je
                                nezbytné
                                pro
                                splnění smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro provedení
                                opatření
                                přijatých před uzavřením smlouvy na žádost tohoto subjektu údajů.
                            </li>
                            <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu
                                čl.
                                22
                                GDPR.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">3. Účely zpracování
                                osobních
                                údajů</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>Splnění smlouvy uzavřené se zákazníkem, nejčastěji vyřízení objednávky, a
                                zákonné
                                povinnosti z plnění smlouvy vyplývající
                            </li>
                            <li>Marketingové činnosti, nejčastěji pak zasílání obchodních sdělení, v souladu se
                                zákonnými důvody
                            </li>
                            <li>Přiměřené zjišťování spokojenosti s poskytnutým zbožím nebo službami</li>
                            <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu
                                čl.
                                22
                                GDPR.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">4. Doba uchovávání
                                údajů</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>Jsou-li údaje zpracovávány ze zákonných důvodů, pak budou zpracovávány po dobu
                                trvání
                                účinků práv a povinností ze smlouvy, a dále po dobu nutnou pro účely archivování
                                podle
                                příslušných obecně závazných právních předpisů, nejdéle však po dobu stanovenou
                                obecně
                                závaznými právními předpisy.
                            </li>
                            <li>Jsou-li osobní údaje zpracovávány na základě uděleného souhlasu, pak jsou
                                zpracovávány
                                nejdéle do té doby, než je odvolán souhlas se zpracováním osobních údajů,
                                nejdéle
                                pak 10
                                let od udělení souhlasu, nebo od poslední provedené objednávky (uplatní se
                                možnost,
                                která nastane později).
                            </li>
                            <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.</li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">5. Příjemci osobních
                                údajů</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>
                                <p>Příjemci osobních údajů jsou osoby:</p>
                                <ul>
                                    <li>{shop.company}</li>
                                </ul>
                            </li>
                            <li>Správce nemá v úmyslu předat osobní údaje subjektu údajů do třetí země,
                                mezinárodní
                                organizaci nebo jiným, než výše uvedeným třetím osobám.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">6. Vaše práva</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>
                                <p>Za podmínek stanovených v GDPR máte právo</p>
                                <ul>
                                    <li></li>
                                    <li>na přístup ke svým osobním údajům dle čl. 15 GDPR</li>
                                    <li>na opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování
                                        dle
                                        čl.
                                        18 GDPR
                                    </li>
                                    <li>na výmaz osobních údajů dle čl. 17 GDPR</li>
                                    <li>vznést námitku proti zpracování dle čl. 21 GDPR</li>
                                    <li>na přenositelnost údajů dle čl. 20 GDPR</li>
                                </ul>
                            </li>
                            <li>Správce nemá v úmyslu předat osobní údaje subjektu údajů do třetí země,
                                mezinárodní
                                organizaci nebo jiným, než výše uvedeným třetím osobám.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">7. Podmínky zabezpečení
                                osobních údajů</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k
                                zabezpečení osobních údajů a jejich úložišť v elektronické i listinné podobě a
                                že ke
                                zpracovávaným osobním údajům mají přístup pouze jím pověřené osoby.
                            </li>
                        </ol>

                    </div>

                    <div className="col-md-12 personal-data wow fadeInLeft">
                        <header className="wrapper__heading wow fadeInLeft">
                            <h3 className="heading heading--3 heading__bordered--left">8. Závěrečná
                                ustanovení</h3>
                            <div></div>
                        </header>

                        <ol>
                            <li>Odesláním formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany
                                osobních
                                údajů
                                a že je v celém rozsahu přijímáte.
                            </li>
                            <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních
                                údajů
                                zveřejní na svých internetových stránkách.
                            </li>
                        </ol>
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <Heading1 title={PersonalData.title}/>

                <section className="section-padding-0-100">
                    <div className="container">
                        {this.getPersonalData()}
                    </div>
                </section>
            </>
        );
    }

}
