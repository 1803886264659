import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Layout} from "./components/FrontModule/Layout";
import {Contact} from "./components/FrontModule/Contact";
import {Home} from "./components/FrontModule/Home";
import {About} from "./components/FrontModule/About";
import {Product} from "./components/FrontModule/Product";
import {Cart} from "./components/FrontModule/Cart";
import {HowToBuy} from "./components/FrontModule/HowToBuy";
import {Postage} from "./components/FrontModule/Postage";
import {ReturnGood} from "./components/FrontModule/ReturnGood";
import {ProductDetail} from "./components/FrontModule/ProductDetail";
import {Register} from "./components/FrontModule/Register";
import {Order} from "./components/FrontModule/Order";
import {Team} from "./components/FrontModule/Team";
import {hidePreloader, responsiveMenu, scrollTop} from "./components/FrontModule/components/utils/Utils";
import {
    getLocalStorageUserObject,
    login, setLocalStorageUser,
    tokenExpired
} from "./components/FrontModule/User";
import './assets/css/style.css';
import {Login} from "./components/FrontModule/components/Login";
import {ConfirmRegistration} from "./components/FrontModule/ConfirmRegistration";
import {GoPay} from "./components/FrontModule/GoPay";

export default class App extends Component {
    static displayName = App.name;
    
    static apiUrl = 'https://ceylonwayapi-webshop.crmplus.cz';
    //static apiUrl = "https://ceylonwaytest-webshop.crmplus.cz";
    static crmUrl = 'https://ceylonway-webclient.crmplus.cz';

    constructor(props) {
        super(props);

        this.handleUser = this.handleUser.bind(this);
        this.handleUserLogin = this.handleUserLogin.bind(this);
        this.handleUserLogout = this.handleUserLogout.bind(this);
        this.handleUserLoginAnnonym = this.handleUserLoginAnnonym.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.handleUpdateDteReady = this.handleUpdateDteReady.bind(this);

        this.child = React.createRef();

        this.state = {
            title: "Base title",
            showBreadcrumb: false,
            userToken: null,
            userLogged: false,
            userAnnonym: false,
            cartUpdated: false, 
            dteReady: false,
            alertMessage: "",
        }

        /*
        axios.interceptors.request.use(
            config => {
                const {origin} = new URL(config.url);
                const allowedOrigins = [this.state.apiUrl];
                const token = getTo;
                if (allowedOrigins.includes(origin)) {
                    config.headers.authorization = `Bearer ${token}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        
         */

    }

    async componentDidMount() {
        hidePreloader();
        responsiveMenu();
        
        //await this.handleUser();

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        hidePreloader();
        scrollTop();
    }

    async handleUser() {

        if (getLocalStorageUserObject() === null || tokenExpired()) {
            //console.log('local store user null');
            localStorage.setItem('user', null);
            await this.handleUserLoginAnnonym();
        }

        /*
        if (tokenExpired()) {
            //console.log('token expired');

            localStorage.setItem('user', null);

            return await this.handleUserLoginAnnonym();
        }
        */
    }


    handleUserLogin() {
        /*
        this.setState({
            userLogged: true
        });
        */
        
        this.handleAlert(
            <h3>Byl jste úspěšně přihlášen</h3>
        );
    }

    async handleUserLogout(access = true) {
        localStorage.setItem('user', null);
        
        /*
        this.setState({
            userAnnonym: false,
        });
        */

        if (access) {
            this.handleAlert(
                <div className="">
                    <h3>Byl jste úspěšně odhlášen</h3>
                </div>
            );
        }

        //await this.handleUserLoginAnnonym();
    }

    async handleUserLoginAnnonym() {
        await login(null, null)
            .then(response => {
                //console.log(response);
                
                setLocalStorageUser(response.data);
                
                this.setState({
                    userAnnonym: true,
                    cartUpdated: true,
                });
                
            })
            .catch(error => {
                //console.log(error);
            });
    }

    handleAlert(content) {
        //console.log(this.child);
        
        if (this.child.current !== null) {
            this.child.current.handleAlert(content);
        }
    }
    
    handleUpdateDteReady(bool) {
        this.setState({
            dteReady: bool,
        })
    }


    render() {

        return (
            <>
                <Layout global={this.state}
                        handleUserLoginAnnonym={this.handleUserLoginAnnonym} 
                        handleAlert={this.handleAlert} 
                        handleUserLogout={this.handleUserLogout}
                        handleUserLogin={this.handleUserLogin} 
                        handleUser={this.handleUser}
                        userLogged={this.state.userLogged} 
                        alertMessage={this.state.alertMessage} 
                        ref={this.child}>
                    <Switch>
                        <Route exact path={Home.url} component={() => <Home/>}/>
                        <Route exact path={About.url} component={() => <About/>}/>
                        {/*<Route exact path={`${Product.url}/:id?`} 
                               component={(props) => <Product
                                   {...props}
                                   handleAlert={this.handleAlert} 
                                   handleUserLogin={this.handleUserLogin}
                                   handleUser={this.handleUser}
                                   handleUserLoginAnnonym={this.handleUserLoginAnnonym}
                               />}/>*/}
                               
                        <Route exact path={Product.url} component={() => window.location = "https://www.loveceylon.cz/"}/>
                        <Route exact path={Cart.url} component={() => window.location = "https://www.loveceylon.cz/"}/>
                        <Route exact path={Contact.url}
                               component={() => <Contact handleAlert={this.handleAlert}/>}/>
                        <Route exact path={Team.url} component={() => <Team/>}/>
                        {/*<Route exact path={Cookie.url} component={() => <Cookie global={this.state}/>}/>*/}
                        {/*<Route exact path={PersonalData.url} component={() => <PersonalData global={this.state}/>}/>*/}
                        {/*<Route exact path={Cart.url}
                               component={() => <Cart handleAlert={this.handleAlert} handleUser={this.handleUser} handleUserLoginAnnonym={this.handleUserLoginAnnonym}/>}/>*/}
                        <Route exact path={HowToBuy.url} component={() => <HowToBuy/>}/>
                        {/*<Route exact path={Payment.url} component={() => <Payment global={this.state}/>}/>*/}
                        <Route exact path={Postage.url} component={() => <Postage/>}/>
                        {/*<Route exact path={Fag.url} component={() => <Fag global={this.state}/>}/>*/}
                        <Route exact path={ReturnGood.url} component={() => <ReturnGood/>}/>
                        <Route exact path={Order.url} component={() => <Order dteReady={this.state.dteReady} handleUpdateDteReady={this.handleUpdateDteReady}/>}/>
                        {/*<Route exact path={Account.url}
                               component={() => <Account global={this.state} handleUserLogout={this.handleUserLogout}
                                                         handleUserLoginAnnonym={this.handleUserLoginAnnonym}/>}/>
                                                         */}

                        <Route exact path={Register.url} component={() => <Register handleAlert={this.handleAlert}/>}/>
                        <Route exact path={Login.url} component={() => <Login handleAlert={this.handleAlert}
                                                                              handleUserLogin={this.handleUserLogin}
                                                                              handleUserLoginAnnonym={this.handleUserLoginAnnonym}/>}/>
                        <Route exact path={ConfirmRegistration.url} component={() => <ConfirmRegistration handleAlert={this.handleAlert}/>}/>
                        <Route exact path={GoPay.url} component={() => <GoPay handleAlert={this.handleAlert}/>}/>
                        <Route path={`${ProductDetail.url}/:id`}
                               component={(props) => <ProductDetail {...props} 
                                                                    handleAlert={this.handleAlert}
                                                                    handleUserLogin={this.handleUserLogin}
                                                                    handleUserLoginAnnonym={this.handleUserLoginAnnonym}
                               />}/>
                    </Switch>
                </Layout>
            </>
        );
    }
}
/*
                       <Route exact path="/" component={() => <Home title="Domů" global={this.state}/>}/>
                       <Route exact path="/o-nas" component={() => <About title="O nás" global={this.state}/>}/>
                       <Route exact path="/eshop" component={() => <Product title="Naše produkty" global={this.state}/>}/>
                       <Route exact path="/kontakt" component={() => <Contact title="Kontakt" global={this.state}/>}/>

                       <Route exact path={Cookie.url} component={() => <Cookie title="Prohlášení cookies" global={this.state}/>}/>
                       <Route exact path={PersonalData.url}
                              component={() => <PersonalData title="Ochrana osobních údajů" global={this.state}/>}/>

                       <Route exact path="/kosik" component={() => <Cart title="Košík" global={this.state}/>}/>

                       <Route exact path={HowToBuy.url} component={() => <HowToBuy global={this.state}/>}/>
                       */