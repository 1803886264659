import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery/dist/jquery';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import buildSitemap from "react-build-sitemap";

window.$ = window.jQuery = jQuery;
let owl_carousel = require('owl.carousel/dist/owl.carousel.min');
window.fn = owl_carousel;

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
//buildSitemap('./sitemap-generator.js', './public', 'https://ceylon-way.cz')

setTimeout(function () {
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>,
        rootElement);
}, 1000);

registerServiceWorker();

