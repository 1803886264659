import React, {Component} from 'react';
import {Link} from "react-router-dom";
import NavMenuItems from "./NavMenuItems";
import {Cart} from "../Cart";
import {Company} from "./Company";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloud, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Login} from "./Login";
import App from "../../../App";
import {isAnnonym, logout} from "../User";
import {hideLoader, showLoader} from "./utils/Utils";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            navItems: this.props.navItems,
            collapsed: true,
            //userLogged: false,
        };

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleLogout() {
        await showLoader();
        
        await logout()
            .then(response => {
                //console.log(response);
                if (response.status === 200) {
                    this.props.handleUserLogout();
                    hideLoader();
                }

            }).catch(error => {
                this.props.handleUserLogout(false);
            });

        await hideLoader();
    }


    getNavAccountContainer() {

        if (isAnnonym()) {
            return (
                <>
                    <Login modal handleAlert={this.props.handleAlert} handleUserLogin={this.props.handleUserLogin}/>
                </>
            )
        }

        return (
            <>
                <button className="" onClick={() => this.handleLogout()}>
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                    <span> Odhlásit se</span>
                </button>

                <a href={App.crmUrl} target="_blank" title="Moje CRM" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faCloud}/>
                    <span> Můj Ceylon</span>
                </a>
            </>
        )

    }

    render() {

        return (
            <>
                <header className="header-area">
                    <div className="top-header-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="top-header-content d-flex align-items-center justify-content-between">

                                        <div className="top-header-meta">
                                            <p>Vítejte u <span>Ceylon-Way</span>, věříme, že se vám s námi bude líbit.
                                            </p>
                                        </div>

                                        <div className="top-header-meta text-right">
                                            {this.getNavAccountContainer()}
                                            <Company/>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="famie-main-menu">
                        <div className="classy-nav-container breakpoint-off">
                            <div className="container">

                                <nav className="classy-navbar justify-content-between" id="famieNav">

                                    <Link to="/" className="nav-brand" title="Ceylon Way">
                                        <img src={require('../../../assets/images/logo/logo.svg')} width="100" alt=""/>
                                    </Link>

                                    <div className="classy-navbar-toggler">
                                        <span className="navbarToggler"><span></span><span></span><span></span></span>
                                    </div>

                                    <div className="classy-menu">

                                        <div className="classycloseIcon">
                                            <div className="cross-wrap">
                                                <span className="top"></span>
                                                <span className="bottom"></span>
                                            </div>
                                        </div>

                                        <div className="classynav">
                                            <ul>
                                                <NavMenuItems setTitle={this.props.setTitle}/>
                                            </ul>

                                            <Cart handleUser={this.props.handleUser}
                                                  handleUserLoginAnnonym={this.props.handleUserLoginAnnonym} nav/>
                                        </div>

                                    </div>
                                </nav>


                                <div className="search-form">
                                    <form action="#" method="get">
                                        <input type="search" name="search" id="search"
                                               placeholder="Type keywords &amp; press enter..."/>
                                        <button type="submit" className="d-none"></button>
                                    </form>

                                    <div className="closeIcon"><i className="fa fa-times" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}
