import axios from 'axios';
import qs from "qs";
import App from "../../App";

export const getLocalStorageUserObject = function () {
    let user = localStorage.user;
    
    if (user === null || user === undefined) {
        return null;
    }
    
    return JSON.parse(user);
}

export const setLocalStorageUser = function(data, anonnym = true) {
    let time = Date.now();
    time = time + (data.expires_in * 1000);
    //time = time + 30000;
    let jsonData = data;
    jsonData.expiresDate = time;
    jsonData.annonym = anonnym;
    return localStorage.setItem("user", JSON.stringify(jsonData));
}

export const isAnnonym = function() {
    
    let user = getLocalStorageUserObject();

    if (user === null) {
        return true;
    }

    return !!(user && user.annonym);
}

export const tokenExpired = function() {
    let user = JSON.parse(localStorage.getItem('user'));
    //console.log(user);
    if (user === null) {
        return true;
    }

    if (user.expiresDate === undefined) {
        return true;
    }

    //console.log(Date.now());
    
    return user.expiresDate < Date.now();
}

export const getToken = function () {
    if (getUser() === null) {
        return null;
    }

    return getUser().access_token;
}

export const userAccessDenied = async function () {
    return await axios.create({
        baseURL: App.apiUrl,
        headers: {'Authorization': 'Bearer ' + await getToken()}
    }).get('/api/checkout/person')
        .then(response => {
            console.log(response);
            return false;
        }).catch(error => {
            console.log(error);
            return true;
    });
}

export const getAdminToken = async function () {
    let username = 'apiadmin';
    let password = 'pi1245admiN8745LoG';

    return login(username, password)
        .then(response => {
            return response.data.access_token;
        })
        .catch(error => {
            return null;
        });
}

export const getUser = function () {
    let user = localStorage.user;

    if (user === null || user === undefined) {
        return null;
    }

    return JSON.parse(user);
}

export const login = async function (username, password) {
    return await axios.post(App.apiUrl + '/api/token',
        qs.stringify({
            username: username,
            password: password,
            grant_type: 'password',
        }),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
}

export const logout = async function () {
    return await axios.create({
        baseURL: App.apiUrl,
        headers: {'Authorization': 'Bearer ' + getToken()}
    }).get(`/api/logout`);
}

