import $ from "jquery";
import axios from "axios";
import App from "../../../../App";
import {getToken} from "../../User";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";


export const shopInfo = function () {
    return {
        company: "Ceylon Way",
        phone: "+420 608 136 126",
        email: "hello@ceylon-way.cz",
        ic: "08536872",
        address: "V Zahradách 925, 252 43 Průhonice",
        invoiceAddress: "V Zahradách 925, 252 43 Průhonice"
    }
}

export const appendScript = (scriptToAppend, async = true) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = async;
    document.body.appendChild(script);
}

export const getProductImage = function (imageId) {
    try {
        return require(`../../../../assets/images/product/cw/${imageId}.jpg`);

    } catch (err) {
        return require(`../../../../assets/images/product/none.jpg`);
    }
}

export const getInfoCompany = async () => {
    const response = await fetch('api/setting');
    return await response.json();
}

export const addToCart = async function (code, quantity = 1) {
    return await axios.create({
        baseURL: App.apiUrl,
        headers: {'Authorization': 'Bearer ' + getToken()}
    }).get(`/api/shoppingcart/add?code=${code}&quantity=${quantity}&tagInt=&tagStr=`);
}

export const hidePreloader = function () {
    let loader = document.getElementById('preloader');
    if (loader !== null) {
        loader.classList.add('preloader--hide');    
    }
    
    
}

export const showPreloader = function () {
    let loader = document.getElementById('preloader');
    if (loader !== null) {
        loader.classList.remove('preloader--show');    
    }
}

export const showLoaderButton = function (submitButton) {
    let loaderTag = '<div class="loader loader--btn"></div>';

    submitButton.empty();
    submitButton.append(loaderTag);
}

export const hideLoaderButton = function (submitButton, btnContent) {
    submitButton.empty();
    submitButton.append(btnContent);
}

export const showLoader = async function () {
    let loader = $('.page-loader');
    
    if (loader !== undefined) {
        loader.show();    
    }
}

export const hideLoader = async function () {
    let loader = $('.page-loader');

    if (loader !== undefined) {
        loader.hide();
    }
}

export const responsiveMenu = function() {
    // Variables
    let navContainer = $('.classy-nav-container');
    let classy_nav = $('.classynav ul');
    let classy_navli = $('.classynav > ul > li');
    let navbarToggler = $('.classy-navbar-toggler');
    let closeIcon = $('.classycloseIcon');
    let navToggler = $('.navbarToggler');
    let classyMenu = $('.classy-menu');
    let navItemLink = $('.js-nav-link');
    let var_window = $(window);

    // default options
    let defaultOpt = $.extend({
        theme: 'light',
        breakpoint: 991,
        openCloseSpeed: 350,
        megaopenCloseSpeed: 700,
        alwaysHidden: false,
        openMobileMenu: 'left',
        dropdownRtl: false,
        stickyNav: false,
        stickyFooterNav: false
    });

    var closeMenu = function () {
        classyMenu.removeClass('menu-on');
        navToggler.removeClass('active');
    }

    var openMenu = function () {
        navToggler.toggleClass('active');
        classyMenu.toggleClass('menu-on');
    }

    function navItemLinkClick() {
        navItemLink.on('click', function (e) {
            if (classyMenu.hasClass('menu-on')) {
                closeMenu();
            }
        })
    }


    // light or dark theme
    if (defaultOpt.theme === 'light' || defaultOpt.theme === 'dark') {
        navContainer.addClass(defaultOpt.theme);
    }

    // open mobile menu direction 'left' or 'right' side
    if (defaultOpt.openMobileMenu === 'left' || defaultOpt.openMobileMenu === 'right') {
        navContainer.addClass(defaultOpt.openMobileMenu);
    }

    // dropdown rtl
    if (defaultOpt.dropdownRtl === true) {
        navContainer.addClass('dropdown-rtl');
    }

    // navbar toggler
    navbarToggler.on('click', function () {
        openMenu();
    });

    // close icon
    closeIcon.on('click', function () {
        closeMenu();
    });

    // add dropdown & megamenu class in parent li class
    classy_navli.has('.dropdown').addClass('cn-dropdown-item');
    classy_navli.has('.megamenu').addClass('megamenu-item');

    // adds toggle button to li items that have children
    classy_nav.find('li a').each(function () {
        if ($(this).next().length > 0) {
            $(this).parent('li').addClass('has-down').append('<span class="dd-trigger"></span>');
        }
    });

    // expands the dropdown menu on each click
    classy_nav.find('li .dd-trigger').on('click', function (e) {
        e.preventDefault();
        $(this).parent('li').children('ul').stop(true, true).slideToggle(defaultOpt.openCloseSpeed);
        $(this).parent('li').toggleClass('active');
    });

    // add padding in dropdown & megamenu item
    $('.megamenu-item').removeClass('has-down');

    // expands the megamenu on each click
    classy_nav.find('li .dd-trigger').on('click', function (e) {
        e.preventDefault();
        $(this).parent('li').children('.megamenu').slideToggle(defaultOpt.megaopenCloseSpeed);
    });

    // check browser width in real-time
    function breakpointCheck() {
        var windoWidth = window.innerWidth;
        if (windoWidth <= defaultOpt.breakpoint) {
            navContainer.removeClass('breakpoint-off').addClass('breakpoint-on');
        } else {
            navContainer.removeClass('breakpoint-on').addClass('breakpoint-off');
        }
    }

    breakpointCheck();
    navItemLinkClick();

    var_window.on('resize', function () {
        breakpointCheck();
    });

    // always hidden enable
    if (defaultOpt.alwaysHidden === true) {
        navContainer.addClass('breakpoint-on').removeClass('breakpoint-off');
    }

    // sticky
    if (defaultOpt.stickyNav === true) {
        var_window.on('scroll', function () {
            if (var_window.scrollTop() > 0) {
                navContainer.addClass('classy-sticky');
            } else {
                navContainer.removeClass('classy-sticky');
            }
        });
    }

    // footer sticky
    if (defaultOpt.stickyFooterNav === true) {
        navContainer.addClass('classy-sticky-footer');
    }
}

export const owlCarousel = function() {
    let welcomeSlide = $('.welcome-slides');

    // :: 5.0 Sliders Active Code
    if ($.fn.owlCarousel) {
        welcomeSlide.owlCarousel({
            items: 1,
            margin: 0,
            loop: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 7000,
            smartSpeed: 1000
        });

        welcomeSlide.on('translate.owl.carousel', function () {
            let slideLayer = $("[data-animation]");
            slideLayer.each(function () {
                let anim_name = $(this).data('animation');
                $(this).removeClass('animated ' + anim_name).css('opacity', '0');
            });
        });

        welcomeSlide.on('translated.owl.carousel', function () {
            let slideLayer = welcomeSlide.find('.owl-item.active').find("[data-animation]");
            slideLayer.each(function () {
                let anim_name = $(this).data('animation');
                $(this).addClass('animated ' + anim_name).css('opacity', '1');
            });
        });

        $("[data-delay]").each(function () {
            let anim_del = $(this).data('delay');
            $(this).css('animation-delay', anim_del);
        });

        $("[data-duration]").each(function () {
            let anim_dur = $(this).data('duration');
            $(this).css('animation-duration', anim_dur);
        });

        $('.testimonial-slides').owlCarousel({
            items: 1,
            margin: 0,
            loop: true,
            dots: false,
            nav: true,
            navText: [<FontAwesomeIcon icon={faArrowLeft}/>, <FontAwesomeIcon icon={faArrowRight}/>],
            autoplay: true,
            autoplayTimeout: 7000,
            smartSpeed: 1000,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
        });
    }
}


export const getIcons = function () {
    return (

        <>
            <div className="col-6 col-sm-4 col-lg">
                <div className="single-benefits-area wow fadeInUp mb-50" data-wow-delay="100ms">
                    <img src={require('../../../../assets/images/icon/organic.svg')} alt=""/>
                    <h5>ORGANIC PRODUCTS</h5>
                </div>
            </div>

            <div className="col-6 col-sm-4 col-lg">
                <div className="single-benefits-area wow fadeInUp mb-50" data-wow-delay="300ms">
                    <img src={require('../../../../assets/images/icon/natural.svg')} alt=""/>
                    <h5>100% NATURAL</h5>
                </div>
            </div>

            <div className="col-6 col-sm-4 col-lg">
                <div className="single-benefits-area wow fadeInUp mb-50" data-wow-delay="500ms">
                    <img src={require('../../../../assets/images/icon/premium.svg')} alt=""/>
                    <h5>PREMIUM QUALITY</h5>
                </div>
            </div>

            <div className="col-6 col-sm-4 col-lg">
                <div className="single-benefits-area wow fadeInUp mb-50" data-wow-delay="700ms">
                    <img src={require('../../../../assets/images/icon/sri-lanka.svg')} alt=""/>
                    <h5>MADE IN SRI LANKA</h5>
                </div>
            </div>

            <div className="col-6 col-sm-4 col-lg">
                <div className="single-benefits-area wow fadeInUp mb-50" data-wow-delay="900ms">
                    <img src={require('../../../../assets/images/icon/ajurveda.svg')} alt=""/>
                    <h5>AYURVEDA PRODUCTS</h5>
                </div>
            </div>
        </>

    )
}

export const navMobile = function () {
    $.fn.classyNav = function (options) {

        /*

        // Variables
        let navContainer = $('.classy-nav-container');
        let classy_nav = $('.classynav ul');
        let classy_navli = $('.classynav > ul > li');
        let navbarToggler = $('.classy-navbar-toggler');
        let closeIcon = $('.classycloseIcon');
        let navToggler = $('.navbarToggler');
        let classyMenu = $('.classy-menu');
        let var_window = $(window);

        // default options
        var defaultOpt = $.extend({
            theme: 'light',
            breakpoint: 991,
            openCloseSpeed: 350,
            megaopenCloseSpeed: 700,
            alwaysHidden: false,
            openMobileMenu: 'left',
            dropdownRtl: false,
            stickyNav: false,
            stickyFooterNav: false
        }, options);

        return this.each(function () {

            // light or dark theme
            if (defaultOpt.theme === 'light' || defaultOpt.theme === 'dark') {
                navContainer.addClass(defaultOpt.theme);
            }

            // open mobile menu direction 'left' or 'right' side
            if (defaultOpt.openMobileMenu === 'left' || defaultOpt.openMobileMenu === 'right') {
                navContainer.addClass(defaultOpt.openMobileMenu);
            }

            // dropdown rtl
            if (defaultOpt.dropdownRtl === true) {
                navContainer.addClass('dropdown-rtl');
            }

            // navbar toggler
            navbarToggler.on('click', function () {
                navToggler.toggleClass('active');
                classyMenu.toggleClass('menu-on');
            });

            // close icon
            closeIcon.on('click', function () {
                classyMenu.removeClass('menu-on');
                navToggler.removeClass('active');
            });

            // add dropdown & megamenu class in parent li class
            classy_navli.has('.dropdown').addClass('cn-dropdown-item');
            classy_navli.has('.megamenu').addClass('megamenu-item');

            // adds toggle button to li items that have children
            classy_nav.find('li a').each(function () {
                if ($(this).next().length > 0) {
                    $(this).parent('li').addClass('has-down').append('<span class="dd-trigger"></span>');
                }
            });

            // expands the dropdown menu on each click
            classy_nav.find('li .dd-trigger').on('click', function (e) {
                e.preventDefault();
                $(this).parent('li').children('ul').stop(true, true).slideToggle(defaultOpt.openCloseSpeed);
                $(this).parent('li').toggleClass('active');
            });

            // add padding in dropdown & megamenu item
            $('.megamenu-item').removeClass('has-down');

            // expands the megamenu on each click
            classy_nav.find('li .dd-trigger').on('click', function (e) {
                e.preventDefault();
                $(this).parent('li').children('.megamenu').slideToggle(defaultOpt.megaopenCloseSpeed);
            });

            // check browser width in real-time
            function breakpointCheck() {
                var windoWidth = window.innerWidth;
                if (windoWidth <= defaultOpt.breakpoint) {
                    navContainer.removeClass('breakpoint-off').addClass('breakpoint-on');
                } else {
                    navContainer.removeClass('breakpoint-on').addClass('breakpoint-off');
                }
            }

            breakpointCheck();

            var_window.on('resize', function () {
                breakpointCheck();
            });

            // always hidden enable
            if (defaultOpt.alwaysHidden === true) {
                navContainer.addClass('breakpoint-on').removeClass('breakpoint-off');
            }

            // sticky
            if (defaultOpt.stickyNav === true) {
                var_window.on('scroll', function () {
                    if (var_window.scrollTop() > 0) {
                        navContainer.addClass('classy-sticky');
                    } else {
                        navContainer.removeClass('classy-sticky');
                    }
                });
            }

            // footer sticky
            if (defaultOpt.stickyFooterNav === true) {
                navContainer.addClass('classy-sticky-footer');
            }
        });
        */
    };
}

export const custom = function () {
    /*
    
    let browserWindow = $(window);

    // :: 1.0 Preloader Active Code
    browserWindow.on('load', function () {
        $('.preloader').fadeOut('slow', function () {
            $(this).remove();
        });
    });
    
    

    // :: 2.0 Tooltip Active Code
    if ($.fn.tooltip) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    // :: 3.0 Nav Active Code
    if ($.fn.classyNav) {
        $('#famieNav').classyNav();
    }

    // :: 4.0 Sticky Active Code
    if ($.fn.sticky) {
        $(".famie-main-menu").sticky({
            topSpacing: 0
        });
    }


    // :: 6.0 ScrollUp Active Code
    if ($.fn.scrollUp) {
        browserWindow.scrollUp({
            scrollSpeed: 1500,
            scrollText: '<i class="arrow_up"></i>'
        });
    }

    // :: 7.0 Video Play Icons Active Code
    if ($.fn.magnificPopup) {
        $('.play-icon').magnificPopup({
            type: 'iframe'
        });
    }

    // :: 8.0 Jarallax Active Code
    if ($.fn.jarallax) {
        $('.jarallax').jarallax({
            speed: 0.2
        });
    }

    // :: 9.0 Prevent Default a Click
    $('a[href="#"]').on('click', function (e) {
        e.preventDefault();
    });

    // :: 10.0 Search Box Active Code
    $('#searchIcon').on('click', function () {
        $('.search-form').toggleClass('search-active');
    });
    $('.closeIcon').on('click', function () {
        $('.search-form').removeClass('search-active');
    });

    // :: 11.0 Wow Active Code
    if (browserWindow.width() > 767) {
        //new WOW().init();
    }
    
     */
}

export const scrollTop = function () {
    window.scrollTo(0, 0);
}

export const getAboutUsFirstP = function () {
    return (
        <p className="time time--1">Náš příběh Ceylon Way začíná v roce <b>1992</b> první návštěvou Srí Lanky a jejím okouzlením.</p>
    );
}

export const getAboutUs = function () {
    return(
        <>
            {getAboutUsFirstP()}
            <p>Poté následovalo mnoho obchodních projektů, edukativních cest, humanitárních a charitativních akcí.</p>
            <p className="time time--2">V roce <b>2002</b> se nám podařilo být ve správnou chvíli na správném místě a koupit na Srí Lance pozemek o rozloze 10.000 m2,pozemek plný palem a tropické vegetace, přímo na dvoukilometrové panenské pláži mezi <b>Uppaveli a Nilavelli.</b></p>
            <p>Zde jsme <b>vybudovali</b> tradiční <a href="http://srilankaresort.cz" target="_blank" title="Resort 108 Palms Beach" rel="noopener noreferrer"> Resort 108 Palms Beach</a>, s ním i organickou zahradu, kde dnes pěstujeme vlastní <b>ajurvédské byliny</b>. Na toto místo, k nám, se chodí lidé z vesnice po staletí modlit do chrámu, o kterém jsou přesvědčeni, že jej chrání místní bohyně. Jednoduše řečeno – náš resort je <b>magické místo</b>.
                 Právě na resortu se také zrodil náš, dnes již rodinný, vztah mezi Shanem – studentem ajurdvédské medicíny – a námi. Shan nám poprvé představil místní mýdlo, lotion, pastu na zuby a další ajurvédské přípravky. Velmi si přál, abychom používali výrobky, které nejsou toxické a jsou v souladu s přírodou. Přál si, abychom se chránili a začali se zabývat prevencí na naší cestě k dlouhověkosti.</p>
            <p>Logickým vyústěním tohoto příběhu byl nápad a <b>touha</b> dovézt ajurvédské výrobky každodenní spotřeby do <b>Čech a Evropy</b>.</p>
            <p>Nápad, dopřát kvalitu a bezpečné <b>přírodní</b> výrobky všem kolem nás.</p>
            <p className="time time--3">23. 9. 2019 vzniká společnost <b>Ceylon Way s.r.o.</b> – spojení Dany, Blanky a Ady, za podpory Marka Němce, který v roce 2019 s velkou pokorou přijal funkci Honorárního konzula Srí Lanky pro Českou republiku. Jeho úkolem je rozvoj vztahů mezi oběma zeměmi, proto přirozeně podporuje naše výrobky a naši společnost Ceylon Way.</p>
            <p><strong>CEYLON</strong> je původní název ostrova, v roce 1972 byl přejmenován na stávající název Srí Lanka. <strong>WAY</strong> je cesta a směr.</p>
            <p>Vše, co je činěno pro zdraví, dlouhověkost a štěstí , je požehnáno. Nám to dává vyšší smysl a naplňuje nás radostí.</p>
            <p className="time time--4">Věříme, že i vy, společně s námi objevíte kouzlo ayurvédy, která je životním stylem.</p>
            <p>Šťastnou cestu společně s námi…  <b>Váš CEYLON WAY</b></p>
        </>
    )
}

export const getProductTextInCart = function(productCount) {
    if (productCount === 1) {
        return "produkt";
    }
    if (productCount < 5) {
        return "produkty";
    }
    
    return "produktů";
}